import React from 'react';

const ClientTicketHeader: React.FunctionComponent = () => (
    <thead>
        <tr>
            <th>Id de ticket</th>
            <th>Categoría</th>
            <th>Incidencia</th>
            <th>Equipo</th>
            <th>Prioridad</th>
            <th>Estado</th>
            <th>Validación</th>
            <th>Comentario al cierre</th>
        </tr>
    </thead>
);

export default React.memo(ClientTicketHeader);
