import Notification from '../models/Notification';
import { Map } from 'immutable';

export const GetAllNotificationsAction = 'GET_ALL_NOTIFICATIONS';
export interface GetAllNotificationsType {
    type: typeof GetAllNotificationsAction;
    notifications: Map<string, Notification>;
}

export const PostNotificationAction = 'POST_NOTIFICATION';
export interface PostNotificationActionType {
    type: typeof PostNotificationAction;
    notification: Notification;
}
