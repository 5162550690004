import React from 'react';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Ticket from '../../redux/models/Ticket';
import './HelpDeskMainTicketRow';
import TicketAssignerRow from '../Tickets/TicketAssignerRow';
import User from '../../redux/models/User';
import Page from '../Page';
import PageHeader from '../PageHeader';
import PageHeaderItem from '../PageHeaderItem';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HelpDeskUnassignedTicketsTable from './HelpDeskUnassignedTicketsTable';

interface Props {
    tickets: Array<Ticket>;
    currentUser: User | undefined;
}

const HelpDeskUnassignedTickets: React.FunctionComponent<Props> = (props: Props) => {
    const { tickets, currentUser } = props;
    return (
        <Page>
            <PageHeader title='Tickets Sin Asignar'>
                <PageHeaderItem title='Dashboard' link='/dashboard' active={false} />
                <PageHeaderItem title='Tickets Sin Asignar' link='/unassigned_tickets' active={true} />
            </PageHeader>
            <Row>
                <Col>
                    <HelpDeskUnassignedTicketsTable title='Tickets Sin Asignar'>
                        <tbody>
                            {currentUser == null
                                ? null
                                : tickets
                                      .filter(ticket => !ticket.recidivism)
                                      .map(ticket => (
                                          <TicketAssignerRow
                                              key={ticket.id}
                                              ticket={ticket}
                                              currentUser={currentUser}
                                          />
                                      ))}
                        </tbody>
                    </HelpDeskUnassignedTicketsTable>
                    <HelpDeskUnassignedTicketsTable title='Tickets con re-incidencia'>
                        <tbody>
                            {currentUser == null
                                ? null
                                : tickets
                                      .filter(ticket => ticket.recidivism)
                                      .map(ticket => (
                                          <TicketAssignerRow
                                              key={ticket.id}
                                              ticket={ticket}
                                              currentUser={currentUser}
                                          />
                                      ))}
                        </tbody>
                    </HelpDeskUnassignedTicketsTable>
                </Col>
            </Row>
        </Page>
    );
};

const mapStateToProps = (state: AppState): Props => ({
    tickets: state.tickets
        .toList()
        .toArray()
        .filter(ticket => ticket.technicianID == null && !ticket.closed)
        .sort((ticketOne, ticketTwo) => (parseInt(ticketOne.id.slice(4)) < parseInt(ticketTwo.id.slice(4)) ? 1 : -1)),
    currentUser: state.users.currentUser
});

export default connect(mapStateToProps)(React.memo(HelpDeskUnassignedTickets));
