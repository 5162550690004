import React from 'react';
import Ticket, { Priority, Category } from '../../redux/models/Ticket';
import LoadingButton from '../LoadingButton';
import { ThunkDispatch } from 'redux-thunk';
import putTicketThunk from '../../redux/thunks/Tickets/putTicketThunk';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import SelectInput from '../SelectInput';
import { priorities } from '../../redux/models/Ticket';
import Input from '../Input';
import User from '../../redux/models/User';
import moment from 'moment';

interface Props {
    ticket: Ticket;
    putTicket?: (ticket: Ticket) => Promise<void>;
    technicians?: Array<User>;
    client?: User | undefined;
    currentUser: User;
}

interface State {
    loading: boolean;
    priority: Priority | null;
    category: Category | null;
    technicianID: string;
    place: string;
}

class TicketAssignerRow extends React.PureComponent<Props, State> {
    public state: Readonly<State> = {
        loading: false,
        priority: this.props.ticket.priority != null ? this.props.ticket.priority : Priority.high,
        category: this.props.ticket.category != null ? this.props.ticket.category : Category.camera,
        technicianID: this.props.technicians!.length > 0 ? this.props.technicians![0].id : '',
        place: this.props.ticket.place ? this.props.ticket.place : ''
    };

    public render() {
        const { ticket, client } = this.props;
        const usernames = this.props
            .technicians!.filter(user => !user.disabled)
            .map(technician => `${technician.name} ${technician.lastname}`);
        const creationDate = moment(ticket.creationDate);
        return (
            <tr>
                <td className='text-muted'>{ticket.id}</td>
                <td className='text-muted'>{creationDate.format('DD/MM/YYYY HH:mm')}</td>
                <td className='text-muted'>{ticket.problem}</td>
                <td className='text-muted'>{ticket.category}</td>
                <td className='text-muted'>{client != null ? `${client.name} ${client.lastname}` : ''}</td>
                <td className='text-muted'>{ticket.description}</td>
                <td className='text-muted'>{ticket.objectID}</td>
                <td>
                    <SelectInput
                        onSelect={this.onPriorityChange}
                        options={priorities.toArray()}
                        defaultValue={this.state.priority != null ? this.state.priority : undefined}
                    />
                </td>
                <td>
                    <SelectInput options={usernames} onSelect={this.onTechnicianChange} />
                </td>
                <td>
                    <Input onTextChange={this.onPlaceChange} defaultValue={this.state.place} />
                </td>
                <td>
                    <LoadingButton
                        variant='success'
                        className='mr-3'
                        onClick={this.assignTicket}
                        disabled={this.state.loading && !this.checkFields()}
                        loading={this.state.loading}
                    >
                        Actualizar
                    </LoadingButton>
                    <LoadingButton variant='danger' onClick={this.closeTicket} loading={this.state.loading}>
                        Cancelar
                    </LoadingButton>
                </td>
            </tr>
        );
    }

    private checkFields() {
        return this.state.priority != null && this.state.category != null && this.state.technicianID !== '';
    }
    private onPlaceChange = (place: string) => this.setState({ place });
    private onPriorityChange = (priority: string) => this.setState({ priority: priority as Priority });
    private onTechnicianChange = (name: string, index: number) =>
        this.setState({ technicianID: this.props.technicians![index].id });

    private assignTicket = () => {
        this.setState({ loading: true });
        const ticket = this.props.ticket.withMutations(ticket => {
            ticket.set('priority', this.state.priority);
            ticket.set('category', this.state.category);
            ticket.set('technicianID', this.state.technicianID);
            ticket.set('place', this.state.place);
            ticket.set('helpDeskID', this.props.currentUser.id);
            ticket.set('assignationDate', new Date());
        });
        console.log(ticket.toJS());
        this.props.putTicket!(ticket);
        this.setState({ loading: false });
    };

    private closeTicket = async (): Promise<void> => {
        this.setState({ loading: true });
        const closeReason = prompt('Comentario al cierre:');
        if (closeReason != null) {
            const newTicket = this.props.ticket.withMutations(ticket => {
                ticket.set('closed', true);
                ticket.set('closeComment', closeReason);
                ticket.set('helpDeskID', this.props.currentUser.id);
                ticket.set('closeDate', new Date());
            });
            await this.props.putTicket!(newTicket);
        }
        this.setState({ loading: false });
    };
}

const mapStateToProps = (state: AppState, ownProps: Props) => ({
    technicians: state.users.technicians.toList().toArray(),
    client: state.users.clients.get(ownProps.ticket.creatorID)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    putTicket: async (ticket: Ticket) => {
        await dispatch(putTicketThunk(ticket));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketAssignerRow);
