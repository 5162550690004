import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { dashboard, closedTickets } from './TechnicianRoutes';
import TicketsAssignedToCurrentUser from '../Tickets/TicketsAssignedToCurrentUser';

const SuperadminRouter = () => (
    <TicketsAssignedToCurrentUser>
        <Route path='/' exact render={() => <Redirect to='/dashboard' />} />
        <Route path={dashboard.path} component={dashboard.component} exact={dashboard.exact} />
        <Route path={closedTickets.path} component={closedTickets.component} exact={closedTickets.exact} />
    </TicketsAssignedToCurrentUser>
);

export default React.memo(SuperadminRouter);
