import { Record } from 'immutable';
import firebase from 'firebase/app';
import {
    SaveFirebaseUserAction,
    SaveFirebaseUserActionType,
    LoadingFirebaseDataAction,
    LoadingFirebaseDataActionType
} from '../actions/authentication';

export interface AuthenticationState {
    firebaseUser: firebase.User | undefined | null;
    loading: boolean;
}
const AuthenticationStateRecord = Record<AuthenticationState>({
    firebaseUser: undefined,
    loading: true
});

const initialState = new AuthenticationStateRecord();

export default (state = initialState, action: SaveFirebaseUserActionType | LoadingFirebaseDataActionType) => {
    switch (action.type) {
        case SaveFirebaseUserAction:
            return state.set('firebaseUser', action.user);
        case LoadingFirebaseDataAction:
            return state.set('loading', action.loading);
        default:
            return state;
    }
};
