import { deleteUser } from '../../actionCreators/users';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import User from '../../models/User';

const deleteUserThunk = (user: User) => async (dispatch: any) => {
    const deleteUserFirebase = firebase.functions().httpsCallable('deleteUser');
    await Promise.all([
        firebase
            .firestore()
            .collection(firestoreStructure.Users.name)
            .doc(user.id)
            .delete(),
        deleteUserFirebase({ id: user.id })
    ]);
    dispatch(deleteUser(user));
};

export default deleteUserThunk;
