import React from 'react';
import subscribeForDateTicketsThunk from '../../redux/thunks/Tickets/subscribeForDateTicketsThunk';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface Props {
    subscribeForDateTickets: () => Promise<void>;
    children: React.ReactNode;
}

class DateTickets extends React.PureComponent<Props> {
    public componentDidMount() {
        this.props.subscribeForDateTickets();
    }

    public render() {
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    subscribeForDateTickets: async () => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        await dispatch(subscribeForDateTicketsThunk(firstDay, lastDay));
    }
});

export default connect(undefined, mapDispatchToProps)(DateTickets);
