import Ticket from '../../models/Ticket';
import PauseReason from '../../models/PauseReason';
import { List } from 'immutable';

const parseTicketFromDocument = (
    document: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
) => {
    const data = document.data();
    const { creationDate, closeDate, initialImages, finalImages, assignationDate, pauseReasons, ...others } = data;
    let parsedReasons = List<PauseReason>();
    if (pauseReasons != null) {
        parsedReasons = parsedReasons.concat(
            pauseReasons.map((reason: any) => {
                const { creationDate, endDate, ...others } = reason;
                return new PauseReason({
                    ...others,
                    creationDate: creationDate != null ? creationDate.toDate() : null,
                    endDate: endDate != null ? endDate.toDate() : null
                });
            })
        );
    }
    const ticket = new Ticket({
        ...others,
        creationDate: creationDate.toDate(),
        closeDate: closeDate ? closeDate.toDate() : null,
        assignationDate: assignationDate ? assignationDate.toDate() : null,
        pauseReasons: parsedReasons,
        initialImages: List(initialImages),
        finalImages: List(finalImages)
    });
    return ticket;
};

export default parseTicketFromDocument;
