import React, { useState } from 'react';
import { connect } from 'react-redux';
import signUpThunk from '../../redux/thunks/Authentication/signUpThunk';
import postUserThunk from '../../redux/thunks/Users/postUserThunk';
import { ThunkDispatch } from 'redux-thunk';
import SignForm from '../Authentication/SignForm';
import Form from 'react-bootstrap/Form';
import Input from '../Input';
import User from '../../redux/models/User';
import { AppState } from '../../redux/reducers';
import eyeLogoOff from '../../img/eye_logo_off.svg';
import eyeLogoOn from '../../img/eye_logo_on.svg';
import { Action } from 'redux';
import NoAuthentication from './NoAuthentication';

interface ReduxProps {
    firebaseUser: firebase.User | undefined | null;
}

interface ReduxFunctions {
    signUp: (email: string, password: string) => Promise<void>;
    postUser: (user: User) => Promise<void>;
}

interface Props extends ReduxProps, ReduxFunctions {}

interface State {
    showSpinner: boolean;
    email: string;
    password: string;
    name: string;
    lastname: string;
    showPassword: boolean;
}

const SignUp: React.FunctionComponent<Props> = (props: Props) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const onEmailChange = (email: string): void => setEmail(email);
    const onPasswordChange = (password: string): void => setPassword(password);
    const onNameChange = (name: string): void => setName(name);
    const onLastnameChange = (lastname: string): void => setLastname(lastname);
    const toggleShowPassword = (): void => setShowPassword(!showPassword);

    const postUser = async (): Promise<void> => {
        if (props.firebaseUser) {
            const user = new User({ email, id: props.firebaseUser.uid, name, lastname });
            await props.postUser(user);
        }
    };
    const signUp = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setShowSpinner(true);
        if (email && password) {
            try {
                await props.signUp(email, password);
                await postUser();
            } catch (error) {
                setShowSpinner(false);
                alert(error.message);
            }
        }
    };

    return (
        <NoAuthentication>
            <SignForm
                title='Crear Cuenta'
                showSpinner={showSpinner}
                loadingButtonTitle='Crear Cuenta'
                alternativeButtonTitle='Iniciar Sesión'
                redirectURL='/signin'
                onSubmit={signUp}
            >
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Input type='text' required onTextChange={onEmailChange} value={email} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Input type='text' required onTextChange={onNameChange} value={name} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Apellido</Form.Label>
                    <Input type='text' required onTextChange={onLastnameChange} value={lastname} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Contraseña</Form.Label>
                    <div className='input-container'>
                        <img
                            src={showPassword ? eyeLogoOff : eyeLogoOn}
                            alt='search icon'
                            className='input-container__icon'
                            onClick={toggleShowPassword}
                        />
                        <Input
                            className='input-container__input'
                            pattern='(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$'
                            required
                            onTextChange={onPasswordChange}
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                        />
                    </div>
                </Form.Group>
            </SignForm>
        </NoAuthentication>
    );
};

const mapStateToProps = (state: AppState): ReduxProps => ({
    firebaseUser: state.authentication.firebaseUser
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, Action>): ReduxFunctions => {
    return {
        signUp: async (email: string, password: string): Promise<void> => {
            await dispatch(signUpThunk(email, password));
        },
        postUser: async (user: User): Promise<void> => {
            await dispatch(postUserThunk(user));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
