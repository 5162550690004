import { saveUser } from '../../actionCreators/users';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import User from '../../models/User';

const postUserThunk = (user: User) => async (dispatch: any) => {
    await firebase
        .firestore()
        .collection(firestoreStructure.Users.name)
        .doc(user.id)
        .set(user.toJS(), { merge: true });
    dispatch(saveUser(user));
};

export default postUserThunk;
