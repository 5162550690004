import {
    SaveUserActionType,
    SaveUserAction,
    GetAllClientsAction,
    GetAllClientsActionType,
    GetAllTechniciansAction,
    GetAllTechniciansActionType,
    SaveCurrentUserAction,
    SaveCurrentUserActionType,
    GetAllUsersAction,
    GetAllUsersActionType,
    DeleteUserAction,
    DeleteUserActionType,
    GetAllUsersForTypeAction,
    GetAllUsersForTypeActionType
} from '../actions/users';
import User, { UserType } from '../models/User';
import { Map } from 'immutable';

export const saveUser: (user: User) => SaveUserActionType = user => {
    return {
        type: SaveUserAction,
        user
    };
};

export const saveCurrentUser: (user: User) => SaveCurrentUserActionType = user => {
    return {
        type: SaveCurrentUserAction,
        user
    };
};

export const getAllClients: (users: Map<string, User>) => GetAllClientsActionType = users => {
    return {
        type: GetAllClientsAction,
        users
    };
};

export const getAllTechnicians: (users: Map<string, User>) => GetAllTechniciansActionType = users => {
    return {
        type: GetAllTechniciansAction,
        users
    };
};

export const getAllUsers: (users: Map<string, User>) => GetAllUsersActionType = users => {
    return {
        type: GetAllUsersAction,
        users
    };
};

export const deleteUser: (user: User) => DeleteUserActionType = user => {
    return {
        type: DeleteUserAction,
        user
    };
};

export const getAllUsersForType: (users: Map<string, User>, userType: UserType) => GetAllUsersForTypeActionType = (
    users,
    userType
) => {
    return {
        type: GetAllUsersForTypeAction,
        users,
        userType
    };
};
