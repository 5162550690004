import {
    GetAllTicketsActionType,
    GetAllTicketsAction,
    SaveTicketAction,
    SaveTicketActionType
} from '../actions/dateTickets';
import dateTicket from '../models/dateTicket';
import { Map } from 'immutable';

export const getAllTickets: (tickets: Map<string, dateTicket>) => GetAllTicketsActionType = tickets => {
    return {
        type: GetAllTicketsAction,
        tickets
    };
};

export const saveTicket: (ticket: dateTicket) => SaveTicketActionType = ticket => {
    return {
        type: SaveTicketAction,
        ticket
    };
};
