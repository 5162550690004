import dateTicket from '../models/dateTicket';
import { Map } from 'immutable';

export const GetAllTicketsAction = 'GET_TICKETS_BY_DATE';
export interface GetAllTicketsActionType {
    type: typeof GetAllTicketsAction;
    tickets: Map<string, dateTicket>;
}

export const SaveTicketAction = 'SAVE_TICKET';
export interface SaveTicketActionType {
    type: typeof SaveTicketAction;
    ticket: dateTicket;
}
