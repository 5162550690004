import React from 'react';
import LoadingButton from '../LoadingButton';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

interface Props {
    loadingButtonTitle: string;
    alternativeButtonTitle: string;
    showSpinner: boolean;
    redirectURL: string;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    title: string;
    children?: React.ReactNode;
}

const SignForm: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <Container className='d-flex justify-content-center align-items-center h-100'>
            <Row className='w-100'>
                <Col xs='1' sm='2' lg='3' />
                <Col xs='10' sm='8' lg='6'>
                    <Form onSubmit={props.onSubmit}>
                        <Form.Row>
                            <Card className='w-100'>
                                <Card.Header>
                                    <Card.Title>{props.title}</Card.Title>
                                </Card.Header>
                                <Card.Body>{props.children}</Card.Body>
                                <Card.Footer>
                                    <LoadingButton
                                        block
                                        type='submit'
                                        variant='success'
                                        spinnerVariant='light'
                                        disabled={props.showSpinner}
                                        loading={props.showSpinner}
                                    >
                                        {props.loadingButtonTitle}
                                    </LoadingButton>
                                    <LinkContainer to={props.redirectURL}>
                                        <Button block>{props.alternativeButtonTitle}</Button>
                                    </LinkContainer>
                                </Card.Footer>
                            </Card>
                        </Form.Row>
                    </Form>
                </Col>
                <Col xs='1' sm='2' lg='3' />
            </Row>
        </Container>
    );
};

export default React.memo(SignForm);
