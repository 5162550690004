import React from 'react';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Ticket from '../../redux/models/Ticket';
import Page from '../Page';
import PageHeader from '../PageHeader';
import PageHeaderItem from '../PageHeaderItem';
import DatePicker from 'react-datepicker';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import 'react-datepicker/dist/react-datepicker.css';
import TicketView from '../Tickets/TicketView';
import Button from 'react-bootstrap/Button';

interface Props {
    tickets: Array<Ticket>;
}

interface State {
    startDate: Date | null;
    endDate: Date | null;
    createReport: boolean;
}

class HelpDeskReportGenerator extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        const tomorrow = new Date();
        tomorrow.setHours(23);
        this.state = {
            startDate: today,
            endDate: tomorrow,
            createReport: false
        };
    }

    public render(): React.ReactNode {
        const { startDate, endDate } = this.state;
        return (
            <Page>
                <PageHeader title='Generador de reporte'>
                    <PageHeaderItem title='Dashboard' link='/dashboard' active={false} />
                    <PageHeaderItem title='Generador de reporte' link='/report_generator' active={true} />
                </PageHeader>
                <Card className='hide'>
                    <Card.Body>
                        <Row>
                            <Col className='d-flex justify-content-center'>
                                <DatePicker onChange={this.setStartDate} selected={this.state.startDate} />
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                <DatePicker onChange={this.setEndDate} selected={this.state.endDate} />
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                <Button onClick={this.toggleReport}>
                                    {this.state.createReport ? 'Limpiar reporte' : 'Crear reporte'}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {this.state.createReport && startDate != null && endDate != null
                    ? this.props.tickets
                          .filter(ticket => ticket.creationDate >= startDate)
                          .filter(ticket => ticket.creationDate < endDate)
                          .map(ticket => <TicketView key={ticket.id} ticket={ticket} />)
                    : null}
            </Page>
        );
    }

    private toggleReport = (): void => this.setState({ createReport: !this.state.createReport });
    private setStartDate = (startDate: Date | null): void => this.setState({ startDate });
    private setEndDate = (endDate: Date | null): void => this.setState({ endDate });
}

const mapStateToProps = (state: AppState): Props => ({
    tickets: state.tickets
        .toList()
        .toArray()
        .sort((ticketOne, ticketTwo) => (parseInt(ticketOne.id.slice(4)) < parseInt(ticketTwo.id.slice(4)) ? 1 : -1))
});

export default connect(mapStateToProps)(React.memo(HelpDeskReportGenerator));
