import { deleteTeamCode } from '../../actionCreators/teamCodes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import TeamCode from '../../models/TeamCode';
import store from '../../store';

const deleteTeamCodeThunk = (teamCode: TeamCode) => async (dispatch: any): Promise<void> => {
    let teamCodes = store.getState().teamCodes;
    const teamCodesDocument = firebase
        .firestore()
        .collection(firestoreStructure.Preferences.name)
        .doc('shared');
    teamCodes = teamCodes.delete(teamCode.id);
    await teamCodesDocument.update({ teamCodes: teamCodes.toJS() });
    dispatch(deleteTeamCode(teamCode));
};

export default deleteTeamCodeThunk;
