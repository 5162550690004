interface FirestoreCollection {
    name: string;
    subCollection?: FirestoreCollection;
}

const UsersCollectionStructure: FirestoreCollection = {
    name: 'Users'
};

const TicketsCollectionStructure: FirestoreCollection = {
    name: 'Tickets'
};

const PreferencesCollectionStructure: FirestoreCollection = {
    name: 'Preferences'
};

const NotificationsCollectionStructure: FirestoreCollection = {
    name: 'Notifications'
};

const TeamCodesCollectionStructure: FirestoreCollection = {
    name: 'TeamCodes'
};

export const firestoreStructure = {
    Users: UsersCollectionStructure,
    Tickets: TicketsCollectionStructure,
    Preferences: PreferencesCollectionStructure,
    Notifications: NotificationsCollectionStructure,
    TeamCodes: TeamCodesCollectionStructure
};
