import React from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

interface Props {
    title: string;
    value?: number;
    icon: string;
    color: string;
    size: number;
    to: string;
}

const HelpDeskColumnButton: React.FunctionComponent<Props> = (props: Props) => (
    <Col xs={12} lg={props.size}>
        <Link to={props.to}>
            <Card className={`widget-info ${props.color}`} style={{ cursor: 'pointer' }}>
                <Card.Body>
                    <Row>
                        <Col className='text-white'>
                            <p>{props.title}</p>
                            <h2 className='font-weight-bold'>{props.value}</h2>
                        </Col>
                        <Col className='text-right'>
                            <i className={`fa ${props.icon} fa-3x text-white`}></i>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Link>
    </Col>
);

export default React.memo(HelpDeskColumnButton);
