import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ClientDashboard from './ClientDashboard';
import ClientTicketCreator from './ClientTicketCreator';
import Technicians from '../Technicians/Technicians';
import Tickets from '../Tickets/ClientTickets';
import TeamCodes from '../TeamCodes';

const ClientRouter: React.FunctionComponent = () => (
    <Technicians>
        <Tickets>
            <TeamCodes>
                <Route path='/' exact render={(): React.ReactNode => <Redirect to='/dashboard' />} />
                <Route path='/dashboard' component={ClientDashboard} exact />
                <Route path='/create_ticket' component={ClientTicketCreator} exact />
            </TeamCodes>
        </Tickets>
    </Technicians>
);

export default React.memo(ClientRouter);
