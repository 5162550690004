import {
    GetAllNotificationsAction,
    GetAllNotificationsType,
    PostNotificationAction,
    PostNotificationActionType
} from '../actions/notifications';
import { Map } from 'immutable';
import Notification from '../models/Notification';

export const getAllNotifications: (
    notifications: Map<string, Notification>
) => GetAllNotificationsType = notifications => {
    return {
        type: GetAllNotificationsAction,
        notifications
    };
};

export const postNotification: (notification: Notification) => PostNotificationActionType = notification => {
    return {
        type: PostNotificationAction,
        notification
    };
};
