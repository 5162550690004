import { saveFirebaseUser, setLoadingFirebaseData } from '../../actionCreators/authentication';
import firebase from 'firebase/app';
import 'firebase/auth';

const signUpThunk = (email: string, password: string) => async (dispatch: any) => {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
    dispatch(saveFirebaseUser(userCredential.user));
    dispatch(setLoadingFirebaseData(false));
};

export default signUpThunk;
