import React from 'react';

const HelpDeskUnassignedTicketHeader: React.FunctionComponent = () => (
    <thead>
        <tr>
            <th>ID ticket</th>
            <th>Fecha y hora</th>
            <th>Incidencia</th>
            <th>Categoría</th>
            <th>Cliente</th>
            <th>Comentarios</th>
            <th>Equipo</th>
            <th style={{ minWidth: '200px' }}>Prioridad</th>
            <th style={{ minWidth: '200px' }}>Técnico</th>
            <th style={{ minWidth: '200px' }}>Lugar</th>
            <th>Acciones</th>
        </tr>
    </thead>
);

export default React.memo(HelpDeskUnassignedTicketHeader);
