import React from 'react';
import User from '../../redux/models/User';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import UserRow from './UserRow';
import { Map } from 'immutable';

interface Props {
    currentUser: User | undefined;
    users: Map<string, User>;
}

const SuperadminDashboard: React.FunctionComponent<Props> = (props: Props) => (
    <Container>
        <Row>
            <Col xs={4} />
            <Col xs={4} className='d-flex justify-content-center mt-3'>
                <LinkContainer className='mx-2' to='/create_user'>
                    <Button>Crear usuario</Button>
                </LinkContainer>
                <LinkContainer className='mx-2' to='/team_codes'>
                    <Button variant='success'>Crear equipo</Button>
                </LinkContainer>
            </Col>
            <Col xs={4} />
            <div className='card mt-3'>
                <div className='card-header d-flex justify-content-between'>
                    <h3 className='card-title'>Lista de usuarios</h3>
                </div>
                <div className='card-body'>
                    <div className='panel-group' id='accordion' role='tablist' aria-multiselectable='true'>
                        <div className='panel panel-default active'>
                            <div className='panel-heading ' role='tab' id='headingOne'></div>
                            <div className='panel-collapse collapse show'>
                                <div className='panel-body'>
                                    <div className='table-responsive content vscroll' style={{ maxHeight: '500px' }}>
                                        <table className='table card-table table-vcenter text-nowrap'>
                                            <thead>
                                                <tr>
                                                    <th className='w-1'>Correo</th>
                                                    <th>Nombre y apellido</th>
                                                    <th>Rol</th>
                                                    <th></th>
                                                    <th>Desactivar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.users.toList().map(user => (
                                                    <UserRow key={user.id} user={user} />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    </Container>
);

const mapStateToProps = (state: AppState) => ({
    currentUser: state.users.currentUser,
    users: state.users.users
});

export default connect(mapStateToProps)(React.memo(SuperadminDashboard));
