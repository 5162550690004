import React from 'react';
import Ticket, { Priority, getPriorityValue } from '../../redux/models/Ticket';
import LoadingButton from '../LoadingButton';
import firebase from 'firebase/app';
import 'firebase/storage';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../redux/reducers';
import putTicketThunk from '../../redux/thunks/Tickets/putTicketThunk';
import { connect } from 'react-redux';
import { List } from 'immutable';
import Notification, { NotificationType } from '../../redux/models/Notification';
import postNotificationThunk from '../../redux/thunks/Notifications/postNotification';
import moment from 'moment';

interface Props {
    ticket: Ticket;
    putTicket: (ticket: Ticket) => Promise<void>;
    postNotification: (notification: Notification) => Promise<void>;
}

interface State {
    loadingPre: boolean;
    loadingPost: boolean;
}

class TicketRow extends React.PureComponent<Props, State> {
    public state: Readonly<State> = {
        loadingPre: false,
        loadingPost: false
    };
    private preImageReference = React.createRef<HTMLInputElement>();
    private postImageReference = React.createRef<HTMLInputElement>();

    public render() {
        const { ticket } = this.props;
        let timeLeft = 'Imposible calcular.';
        if (ticket.assignationDate != null && ticket.priority != null) {
            const assignationMoment = moment(ticket.assignationDate);
            const end = moment();
            const duration = moment.duration(end.diff(assignationMoment)).asHours();
            const priorityValue = getPriorityValue(ticket.priority);
            if (priorityValue > duration) {
                timeLeft = `${(priorityValue - duration).toFixed(2)} horas restantes`;
            }
        }

        return (
            <div className='col-sm-4'>
                <div className='card'>
                    <div className={`card-status card-status-left br-bl-7 br-tl-7 ${this.getPriorityColor()}`}></div>
                    <div className='card-header'>
                        <h3 className='card-title'>Ticket {ticket.id}</h3>
                    </div>
                    <div className='card-body'>
                        <p>Categoría: {ticket.category}</p>
                        <p>Incidencia: {ticket.problem}</p>
                        <p>Tiempo restante: {timeLeft}</p>
                        <p>Equipo: {ticket.objectID}</p>
                        <p>Lugar: {ticket.place}</p>

                        <div className='d-flex justify-content-between mb-3'>
                            <input
                                type='file'
                                multiple
                                ref={this.preImageReference}
                                onChange={this.uploadPreImages}
                                style={{ display: 'none' }}
                                accept='image/png, image/jpeg'
                            />
                            <LoadingButton
                                onClick={this.triggerPreImageSelector}
                                loading={this.state.loadingPre}
                                variant='primary'
                            >
                                Fotografía Preliminar
                            </LoadingButton>
                            <input
                                type='file'
                                multiple
                                ref={this.postImageReference}
                                onChange={this.uploadPostImages}
                                style={{ display: 'none' }}
                                accept='image/png, image/jpeg'
                            />
                            <LoadingButton
                                onClick={this.triggerPostImageSelector}
                                loading={this.state.loadingPre}
                                variant='primary'
                            >
                                Fotografía Terminación
                            </LoadingButton>
                        </div>

                        {this.props.ticket.paused ? null : (
                            <>
                                <LoadingButton
                                    className='btn-block'
                                    variant='success'
                                    onClick={this.createCloseNotification}
                                >
                                    Solicitar Cierre
                                </LoadingButton>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private getPriorityColor() {
        const { ticket } = this.props;
        if (ticket.priority === Priority.high) {
            return 'bg-red';
        } else if (ticket.priority === Priority.medium) {
            return 'bg-yellow';
        } else {
            return 'bg-green';
        }
    }

    private triggerPreImageSelector = () => {
        this.preImageReference.current?.click();
    };

    private triggerPostImageSelector = () => {
        this.postImageReference.current?.click();
    };

    private uploadPreImages = async () => {
        const files = this.preImageReference.current?.files;
        const urls = await this.uploadImages(files);
        const newTicket = this.props.ticket.set('initialImages', this.props.ticket.initialImages.concat(urls));
        await this.props.putTicket(newTicket);
    };

    private uploadPostImages = async () => {
        const files = this.postImageReference.current?.files;
        const urls = await this.uploadImages(files);
        const newTicket = this.props.ticket.set('finalImages', this.props.ticket.finalImages.concat(urls));
        await this.props.putTicket(newTicket);
    };

    private uploadImages = async (files: FileList | null | undefined) => {
        let urls = List();
        if (files != null) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                urls = urls.push(await this.uploadFile(file));
            }
        }
        return urls;
    };

    private uploadFile = async (file: File) => {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`images/${this.props.ticket.id}-${Date.now()}-${file.name}`);
        const snapshot = await imageRef.put(file);
        return snapshot.ref.getDownloadURL();
    };

    private createCloseNotification = async () => {
        const closeComment = prompt('Describa la razón de la pausa:');
        if (closeComment) {
            const notification = new Notification({
                type: NotificationType.closeTicket,
                text: `Se a creado una solicitud de cierre para el ticket ${this.props.ticket.id}\nEl comentario del tecnico es: ${closeComment}`
            });
            await this.props.postNotification(notification);
        }
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    putTicket: async (ticket: Ticket) => {
        await dispatch(putTicketThunk(ticket));
    },
    postNotification: async (notification: Notification) => {
        await dispatch(postNotificationThunk(notification));
    }
});

export default connect(null, mapDispatchToProps)(TicketRow);
