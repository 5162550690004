import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
    dashboard,
    allTickets,
    unassignedTickets,
    openTickets,
    pausedTickets,
    closedTickets,
    reportGenerator
} from './HelpDeskRoutes';
import Tickets from '../Tickets/Tickets';
import Technicians from '../Technicians/Technicians';
import Clients from '../Clients/Clients';
import Notifications from '../Notifications';
import HelpDesks from './HelpDesks';
import TicketPage from '../Tickets/TicketPage';

const HelpDeskRouter: React.FunctionComponent = () => (
    <Tickets>
        <Technicians>
            <Clients>
                <HelpDesks>
                    <Route path='/notifications' component={Notifications} exact={true} />
                    <Route path='/tickets/:ticketID' component={TicketPage} exact={true} />
                    <Route path='/' exact render={(): React.ReactNode => <Redirect to='/dashboard' />} />
                    <Route path={dashboard.path} component={dashboard.component} exact={dashboard.exact} />
                    <Route path={allTickets.path} component={allTickets.component} exact={allTickets.exact} />
                    <Route
                        path={reportGenerator.path}
                        component={reportGenerator.component}
                        exact={reportGenerator.exact}
                    />
                    <Route
                        path={unassignedTickets.path}
                        component={unassignedTickets.component}
                        exact={unassignedTickets.exact}
                    />
                    <Route path={openTickets.path} component={openTickets.component} exact={openTickets.exact} />
                    <Route path={pausedTickets.path} component={pausedTickets.component} exact={pausedTickets.exact} />
                    <Route path={closedTickets.path} component={closedTickets.component} exact={closedTickets.exact} />
                </HelpDesks>
            </Clients>
        </Technicians>
    </Tickets>
);

export default React.memo(HelpDeskRouter);
