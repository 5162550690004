import React from 'react';
import subscribeForAllTicketsThunk from '../../redux/thunks/Tickets/subscribeForAllTicketsThunk';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface Props {
    subscribeForAllTickets: () => Promise<void>;
    children: React.ReactNode;
}

class Tickets extends React.PureComponent<Props> {
    public componentDidMount() {
        this.props.subscribeForAllTickets();
    }

    public render() {
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    subscribeForAllTickets: async () => {
        await dispatch(subscribeForAllTicketsThunk());
    }
});

export default connect(undefined, mapDispatchToProps)(Tickets);
