import { Record, List } from 'immutable';
import PauseReason from './PauseReason';
import { UserType } from './User';

export enum Category {
    camera = 'Cámara',
    server = 'Servidor',
    workstation = 'Estación de trabajo',
    joystick = 'Joystick',
    videowall = 'Videowall',
    maintenance = 'Mantenimiento'
}

export const categories = List([
    Category.camera,
    Category.server,
    Category.workstation,
    Category.joystick,
    Category.videowall,
    Category.maintenance
]);

export const getProblemsForCategory = (category: Category): Array<string> => {
    switch (category) {
        case Category.camera:
            return ['Borrosa', 'Desconectada', 'Pérdida de grabación', 'Blanco y negro', 'Volteada', 'Otro'];
        case Category.server:
            return ['Se reinicia', 'No conecta', 'Pérdida de grabación', 'Otro'];
        case Category.workstation:
            return ['No responde', 'Monitor', 'Se reinicia Axxon', 'No abre Axxon', 'Otro'];
        case Category.joystick:
            return ['Se traba', 'No responde', 'Otro'];
        case Category.videowall:
            return ['Sin imagen', 'Parpadea', 'Otro'];
        case Category.maintenance:
            return ['Control de acceso', 'Sistema de incendio', 'Otro'];
    }
};

export enum Priority {
    high = 'Alta (4hrs.)',
    medium = 'Media (6hrs.)',
    low = 'Baja (8hrs.)'
}

export const getPriorityValue = (priority: Priority): number => {
    switch (priority) {
        case Priority.high:
            return 4;
        case Priority.medium:
            return 6;
        case Priority.low:
            return 8;
    }
};

export const priorities = List([Priority.high, Priority.medium, Priority.low]);

export interface TicketData {
    category: Category | null;
    problem: string | null;
    creationDate: Date;
    objectID: string;
    description: string;
    initialImages: List<string>;
    finalImages: List<string>;
    id: string;
    closed: boolean;
    technicianID: string | null;
    paused: boolean;
    verified: boolean;
    priority: Priority | null;
    pauseReasons: List<PauseReason>;
    creatorID: string;
    recidivism: boolean;
    closeDate: Date | null;
    helpDeskID: string | null;
    validationComment: string | null;
    closeComment: string | null;
    place: string;
    validator: string | null;
    assignationDate: Date | null;
    clientType: string;
}

const TicketRecord = Record<TicketData>({
    category: null,
    problem: null,
    creationDate: new Date(),
    objectID: '',
    description: '',
    initialImages: List(),
    finalImages: List(),
    id: '',
    closed: false,
    technicianID: null,
    paused: false,
    verified: false,
    priority: null,
    pauseReasons: List(),
    creatorID: '',
    recidivism: false,
    closeDate: null,
    helpDeskID: null,
    validationComment: null,
    closeComment: null,
    place: '',
    validator: null,
    assignationDate: null,
    clientType: UserType.clientT1
});

export default class Ticket extends TicketRecord {}
