import { getAllNotifications } from '../../actionCreators/notifications';
import Notification, { NotificationType } from '../../models/Notification';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import { Map } from 'immutable';

const subscribeForAllTicketsThunk = (types: Array<NotificationType>) => (dispatch: any) => {
    types.forEach(notificationType => {
        firebase
            .firestore()
            .collection(firestoreStructure.Notifications.name)
            .where('type', '==', notificationType)
            .onSnapshot(ticketsData => {
                let notifications = Map<string, Notification>();
                ticketsData.docs.forEach(notificationDocument => {
                    const data = notificationDocument.data();
                    const { creationDate, ...others } = data;
                    const notification = new Notification({
                        ...others,
                        creationDate: creationDate.toDate()
                    });
                    notifications = notifications.set(notification.id, notification);
                });
                dispatch(getAllNotifications(notifications));
            });
    });
};

export default subscribeForAllTicketsThunk;
