import {
    GetAllTicketsActionType,
    GetAllTicketsAction,
    SaveTicketAction,
    SaveTicketActionType
} from '../actions/tickets';
import Ticket from '../models/Ticket';
import { Map } from 'immutable';

export const getAllTickets: (tickets: Map<string, Ticket>) => GetAllTicketsActionType = tickets => {
    return {
        type: GetAllTicketsAction,
        tickets
    };
};

export const saveTicket: (ticket: Ticket) => SaveTicketActionType = ticket => {
    return {
        type: SaveTicketAction,
        ticket
    };
};
