import User from '../../models/User';
import postUserThunk from './postUserThunk';
import firebase from 'firebase/app';
import 'firebase/functions';

const postUserAndRegisterThunk = (user: User, password: string) => async (dispatch: any) => {
    const createUser = firebase.functions().httpsCallable('createUser');
    const result = await createUser({ email: user.email, password });
    if (result.data.id != null) {
        const finalUser = user.set('id', result.data.id);
        await dispatch(postUserThunk(finalUser));
    }
};

export default postUserAndRegisterThunk;
