import React from 'react';
import Ticket from '../../redux/models/Ticket';
import LoadingButton from '../LoadingButton';
import { ThunkDispatch } from 'redux-thunk';
import putTicketThunk from '../../redux/thunks/Tickets/putTicketThunk';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import User, { UserType } from '../../redux/models/User';
import PauseReason from '../../redux/models/PauseReason';
import moment from 'moment';
import TableRowText from '../TableRowText';
import { Action } from 'redux';
import { Redirect } from 'react-router';

const sharedDateFormat = 'DD/MM/YYYY HH:mm';

interface ReduxProps {
    client?: User;
    technician?: User;
    helpDesk?: User;
    validator?: User;
}

interface ReduxFunctions {
    putTicket: (ticket: Ticket) => Promise<void>;
}

interface ExternalProps {
    ticket: Ticket;
}

interface Props extends ReduxProps, ReduxFunctions, ExternalProps { }

interface State {
    loading: boolean;
    redirect: boolean;
}

class HelpDeskMainTicketRow extends React.PureComponent<Props, State> {
    public state: Readonly<State> = {
        loading: false,
        redirect: false
    };

    public render(): React.ReactNode {
        const { ticket, client, technician, validator, helpDesk } = this.props;

        if (this.state.redirect) {
            return <Redirect to={`/tickets/${ticket.id}`} />;
        }

        let pauseTime = 'No ha sido pausado';
        let pauseReason = 'No ha sido pausado';
        if (ticket.pauseReasons.size > 0) {
            const lastValue: PauseReason = ticket.pauseReasons.last();
            pauseReason = lastValue.reason;
            const creation = moment(lastValue.creationDate);
            const end = moment(lastValue.endDate);
            const duration = moment.duration(end.diff(creation));
            pauseTime = `${duration.asHours()} horas`;
        }

        const creationDate = moment(ticket.creationDate);
        const closeDate = ticket.closeDate != null ? moment(ticket.closeDate) : undefined;
        const assignationDate = ticket.assignationDate != null ? moment(ticket.assignationDate) : undefined;

        return (
            <tr>
                <TableRowText text={ticket.id} onClick={this.redirectToTicket} />
                <TableRowText text={ticket.category} />
                <TableRowText text={ticket.problem} />
                <TableRowText text={ticket.objectID} />
                <TableRowText text={client != null ? `${client.name} ${client.lastname}` : ''} />
                <TableRowText text={creationDate.format(sharedDateFormat)} />
                <TableRowText
                    text={assignationDate != null ? assignationDate.format(sharedDateFormat) : 'Sin asignar'}
                />
                <TableRowText text={closeDate != null ? closeDate.format(sharedDateFormat) : 'Sin cerrar'} />
                <TableRowText text={ticket.priority} />
                <TableRowText text={technician != null ? `${technician.name} ${technician.lastname}` : ''} />
                <TableRowText
                    text={validator != null && ticket.closed ? `${validator.name} ${validator.lastname}` : 'Automático'}
                />
                <TableRowText text={helpDesk != null ? `${helpDesk.name} ${helpDesk.lastname}` : 'Sin asignar'} />
                <td>
                    {ticket.closed ? (
                        <LoadingButton variant='primary' className='mr-2 w-100'>
                            <i className='fa fa-check'></i> Cerrado
                        </LoadingButton>
                    ) : (
                            <>
                                {ticket.paused ? (
                                    <LoadingButton variant='success' className='w-50' onClick={this.continueTicket}>
                                        <i className='fa fa-play'></i> Reactivar
                                    </LoadingButton>
                                ) : (
                                        <LoadingButton variant='warning' className='w-50' onClick={this.pauseTicket}>
                                            <i className='fa fa-pause'></i> Pausar
                                        </LoadingButton>
                                    )}
                                <LoadingButton className='w-50 ml-2' variant='danger' onClick={this.closeTicket}>
                                    <i className='fa fa-lock'></i> Cerrar
                            </LoadingButton>
                            </>
                        )}
                </td>
                <TableRowText text={pauseReason} />
                <TableRowText text={pauseTime} />
                <TableRowText text={ticket.closeComment} />
                <TableRowText text={ticket.validationComment} />
            </tr>
        );
    }

    private redirectToTicket = (): void => {
        this.setState({ redirect: true });
    };

    private continueTicket = async (): Promise<void> => {
        this.setState({ loading: true });
        let lastPauseReason = this.props.ticket.pauseReasons.last<PauseReason>();
        if (lastPauseReason != null) {
            lastPauseReason = lastPauseReason.set('endDate', new Date());
        }
        const finalTicket = this.props.ticket.withMutations(ticket => {
            ticket.set('paused', false);
            if (lastPauseReason != null) {
                const index = ticket.pauseReasons.findIndex(value => value.id === lastPauseReason.id);
                const newPauseReasons = ticket.pauseReasons.set(index, lastPauseReason);
                ticket.set('pauseReasons', newPauseReasons);
            }
        });
        await this.props.putTicket(finalTicket);
        this.setState({ loading: false });
    };

    private pauseTicket = async (): Promise<void> => {
        this.setState({ loading: true });
        const pauseReason = prompt('Motivo de pausa:');
        if (pauseReason != null) {
            const newPauseReason = new PauseReason({
                id: `${this.props.ticket.id}-${Date.now()}`,
                reason: pauseReason
            });
            const newTicket = this.props.ticket.withMutations(ticket => {
                ticket.set('paused', true);
                ticket.set('pauseReasons', ticket.pauseReasons.push(newPauseReason));
            });
            await this.props.putTicket(newTicket);
        }
        this.setState({ loading: false });
    };

    private closeTicket = async (): Promise<void> => {
        this.setState({ loading: true });
        const closeReason = prompt('Comentario al cierre:');
        if (closeReason != null) {
            const newTicket = this.props.ticket.withMutations(ticket => {
                ticket.set('closed', true);
                ticket.set('closeComment', closeReason);
                ticket.set('closeDate', new Date());
            });
            await this.props.putTicket(newTicket);
        }
        this.setState({ loading: false });
    };
}

const mapStateToProps = (state: AppState, ownProps: ExternalProps): ReduxProps => {
    const client = state.users.clients.get(ownProps.ticket.creatorID);
    let technician: User | undefined;
    if (state.users.currentUser?.type === UserType.technician) {
        technician = state.users.currentUser;
    } else {
        technician =
            ownProps.ticket.technicianID != null
                ? state.users.technicians.get(ownProps.ticket.technicianID)
                : undefined;
    }
    const helpDesk =
        ownProps.ticket.helpDeskID != null ? state.users.helpDesks.get(ownProps.ticket.helpDeskID) : undefined;
    const validator =
        ownProps.ticket.validator != null ? state.users.clients.get(ownProps.ticket.validator) : undefined;
    return {
        client,
        technician,
        helpDesk,
        validator
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, Action>): ReduxFunctions => ({
    putTicket: async (ticket: Ticket): Promise<void> => {
        await dispatch(putTicketThunk(ticket));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpDeskMainTicketRow);
