import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../redux/models/FirebaseStructure';

const getTicketIDAndUpdate = async (): Promise<number> => {
    const reference = firebase
        .firestore()
        .collection(firestoreStructure.Preferences.name)
        .doc('shared');
    const document = await reference.get();
    if (document.exists) {
        const data = document.data();
        if (data != null) {
            const value = data.currentID;
            data.currentID = value + 1;
            await reference.set(data, { merge: true });
            return value;
        } else {
            throw Error;
        }
    } else {
        throw Error;
    }
};

export default getTicketIDAndUpdate;
