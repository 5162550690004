import React from 'react';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Ticket from '../../redux/models/Ticket';
import { Map } from 'immutable';
import './HelpDeskMainTicketRow';
import HelpDeskMainTicketRow from './HelpDeskMainTicketRow';
import PageHeader from '../PageHeader';
import PageHeaderItem from '../PageHeaderItem';
import Page from '../Page';
import Row from 'react-bootstrap/Row';
import HelpDeskColumnButton from './HelpDeskColumnButton';
import HelpDeskMainTicketsTable from './HelpDeskMainTicketsTable';

interface Props {
    tickets: Map<string, Ticket>;
}

const HelpDeskDashboard: React.FunctionComponent<Props> = (props: Props) => {
    const { tickets } = props;
    let openTickets = 0;
    let unAssignedTickets = 0;
    let pausedTickets = 0;
    let closedTickets = 0;

    const ticketsArray = tickets
        .toList()
        .toArray()
        .sort((ticketOne, ticketTwo) => (parseInt(ticketOne.id.slice(4)) < parseInt(ticketTwo.id.slice(4)) ? 1 : -1));
    ticketsArray.forEach(ticket => {
        if (ticket.closed) {
            closedTickets += 1;
        } else {
            openTickets += 1;
            if (ticket.technicianID == null) {
                unAssignedTickets += 1;
            }

            if (ticket.paused && !ticket.closed) {
                pausedTickets += 1;
            }
        }
    });

    return (
        <Page>
            <PageHeader title='Todos los tickets'>
                <PageHeaderItem title='Dashboard' link='/dashboard' active={false} />
            </PageHeader>
            <Row>
                <HelpDeskColumnButton
                    color='bg-blue'
                    title='Todos los tickets'
                    size={4}
                    value={ticketsArray.length}
                    icon='fa-align-center'
                    to='/all_tickets'
                />
                <HelpDeskColumnButton
                    color='bg-orange'
                    title='Tickets sin asignar'
                    size={4}
                    value={unAssignedTickets}
                    icon='fa-user-times'
                    to='/unassigned_tickets'
                />
                <HelpDeskColumnButton
                    color='bg-yellow'
                    title='Generador de reporte'
                    size={4}
                    icon='fa-database'
                    to='/report_generator'
                />
            </Row>

            <Row>
                <HelpDeskColumnButton
                    color='bg-teal'
                    title='Tickets abiertos'
                    size={4}
                    value={openTickets}
                    icon='fa-user'
                    to='/open_tickets'
                />
                <HelpDeskColumnButton
                    color='bg-gray'
                    title='Tickets pausados'
                    size={4}
                    value={pausedTickets}
                    icon='fa-pause'
                    to='/paused_tickets'
                />
                <HelpDeskColumnButton
                    color='bg-green'
                    title='Tickets cerrados'
                    size={4}
                    value={closedTickets}
                    icon='fa-check'
                    to='closed_tickets'
                />
            </Row>
            <HelpDeskMainTicketsTable title='Tickets Recientes'>
                <tbody>
                    {ticketsArray
                        .slice(ticketsArray.length > 15 ? ticketsArray.length - 15 : 0, ticketsArray.length)
                        .map(ticket => (
                            <HelpDeskMainTicketRow key={ticket.id} ticket={ticket} />
                        ))}
                </tbody>
            </HelpDeskMainTicketsTable>
        </Page>
    );
};

const mapStateToProps = (state: AppState): Props => ({
    tickets: state.tickets
});

export default connect(mapStateToProps)(React.memo(HelpDeskDashboard));
