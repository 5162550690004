import {
    GetAllTeamCodesAction,
    GetAllTeamCodesActionType,
    SaveTeamCodeAction,
    SaveTeamCodeActionType,
    DeleteTeamCodeAction,
    DeleteTeamCodeActionType
} from '../actions/teamCodes';
import TeamCode from '../models/TeamCode';
import { Map } from 'immutable';

export const initialState = Map<string, TeamCode>();

export type TeamCodesState = typeof initialState;

export default (
    state = initialState,
    action: GetAllTeamCodesActionType | SaveTeamCodeActionType | DeleteTeamCodeActionType
) => {
    switch (action.type) {
        case GetAllTeamCodesAction:
            return state.concat(action.teamCodes);
        case SaveTeamCodeAction:
            return state.set(action.teamCode.id, action.teamCode);
        case DeleteTeamCodeAction:
            return state.delete(action.teamCode.id);
        default:
            return state;
    }
};
