import User, { UserType } from '../models/User';
import { Map } from 'immutable';
import { Action } from 'redux';

export const SaveUserAction = 'SAVE_USER';
export interface SaveUserActionType {
    type: typeof SaveUserAction;
    user: User;
}

export const SaveCurrentUserAction = 'SAVE_CURRENT_USER';
export interface SaveCurrentUserActionType extends Action {
    type: typeof SaveCurrentUserAction;
    user: User;
}

export const GetAllClientsAction = 'GET_ALL_CLIENTS';
export interface GetAllClientsActionType {
    type: typeof GetAllClientsAction;
    users: Map<string, User>;
}

export const GetAllTechniciansAction = 'GET_ALL_TECHNICIANS';
export interface GetAllTechniciansActionType {
    type: typeof GetAllTechniciansAction;
    users: Map<string, User>;
}

export const GetAllUsersAction = 'GET_ALL_USERS';
export interface GetAllUsersActionType {
    type: typeof GetAllUsersAction;
    users: Map<string, User>;
}

export const DeleteUserAction = 'DELETE_USER';
export interface DeleteUserActionType {
    type: typeof DeleteUserAction;
    user: User;
}

export const GetAllUsersForTypeAction = 'GET_ALL_USERS_FOR_TYPE';
export interface GetAllUsersForTypeActionType {
    type: typeof GetAllUsersForTypeAction;
    users: Map<string, User>;
    userType: UserType;
}
