import * as React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import '../styles/Loading.scss';

const Loading = () => (
    <div className='loading-container'>
        <Spinner animation='border' />
    </div>
);

export default React.memo(Loading);
