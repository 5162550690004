import { getAllUsersForType } from '../../actionCreators/users';
import User, { UserType } from '../../models/User';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import { Map } from 'immutable';

const subscribeForAllUsersOfType = (userType: UserType) => (dispatch: any) => {
    return firebase
        .firestore()
        .collection(firestoreStructure.Users.name)
        .where('type', '==', userType)
        .onSnapshot(usersData => {
            let users = Map<string, User>();
            usersData.docs.forEach(userDocument => {
                const user = new User(userDocument.data());
                users = users.set(user.id, user);
            });
            dispatch(getAllUsersForType(users, userType));
        });
};

export default subscribeForAllUsersOfType;
