import { getAllClients } from '../../actionCreators/users';
import User, { UserType } from '../../models/User';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import { Map } from 'immutable';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { GetAllClientsActionType } from '../../actions/users';

const subscribeForAllClientsThunk = () => (dispatch: ThunkDispatch<void, AppState, GetAllClientsActionType>): void => {
    firebase
        .firestore()
        .collection(firestoreStructure.Users.name)
        .where('type', '==', UserType.clientT1)
        .onSnapshot(usersData => {
            let users = Map<string, User>();
            usersData.docs.forEach(userDocument => {
                const user = new User(userDocument.data());
                users = users.set(user.id, user);
            });
            dispatch(getAllClients(users));
        });

    firebase
        .firestore()
        .collection(firestoreStructure.Users.name)
        .where('type', '==', UserType.clientT2)
        .onSnapshot(usersData => {
            let users = Map<string, User>();
            usersData.docs.forEach(userDocument => {
                const user = new User(userDocument.data());
                users = users.set(user.id, user);
            });
            dispatch(getAllClients(users));
        });
};

export default subscribeForAllClientsThunk;
