import React, { useState } from 'react';
import Ticket, { Category, categories, getProblemsForCategory } from '../../redux/models/Ticket';
import postTicketThunk from '../../redux/thunks/Tickets/postTicketThunk';
import postNotificationThunk from '../../redux/thunks/Notifications/postNotification';
import SelectInput from '../SelectInput';
import Input from '../Input';
import LoadingButton from '../LoadingButton';
import { AppState } from '../../redux/reducers';
import User from '../../redux/models/User';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PageHeader from '../PageHeader';
import Notification, { NotificationType } from '../../redux/models/Notification';
import PageHeaderItem from '../PageHeaderItem';
import Page from '../Page';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Autosuggest from 'react-autosuggest';
import { List } from 'immutable';
import TeamCode from '../../redux/models/TeamCode';
import getTicketIDAndUpdate from '../../utils/getTicketIDAndUpdate';
import { PostNotificationActionType } from '../../redux/actions/notifications';
import { SaveTicketActionType } from '../../redux/actions/tickets';

interface ReduxProps {
    currentUser: User | undefined;
    teamCodes: List<TeamCode>;
}

interface ReduxFunctions {
    postTicket: (ticket: Ticket) => Promise<void>;
    postNotification: (notification: Notification) => Promise<void>;
}

interface Props extends ReduxProps, ReduxFunctions {}

const getSuggestionValue = (suggestion: TeamCode): string => {
    return suggestion.value;
};

const renderSuggestion = (suggestion: TeamCode): React.ReactNode => (
    <div className='my-2 py-2 px-2' style={{ borderRadius: '5px', backgroundColor: '#343b4a' }}>
        {suggestion.value}
    </div>
);

const ClientTicketCreator: React.FunctionComponent<Props> = (props: Props) => {
    const [category, setCategory] = useState(Category.camera);
    const [problem, setProblem] = useState(getProblemsForCategory(category)[0]);
    const [objectID, setObjectID] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [teamCodes, setTeamCodes] = useState(List<TeamCode>());

    const onCategoryChange = (category: string): void => {
        setCategory(category as Category);
        setProblem(getProblemsForCategory(category as Category)[0]);
    };
    const onProblemChange = (problem: string): void => setProblem(problem);
    const onDescriptionChange = (description: string): void => setDescription(description);
    const objectIDChange = (event: React.ChangeEvent<HTMLInputElement>, change: Autosuggest.ChangeEvent): void =>
        setObjectID(change.newValue);
    const onSuggestionsClearRequested = (): void => setTeamCodes(props.teamCodes.toList());

    const getSuggestions = (value: string): Array<TeamCode> => {
        const inputValue = value.trim().toLowerCase();
        const items =
            inputValue === ''
                ? props.teamCodes
                : props.teamCodes.filter(teamCode => {
                      return teamCode.value.toLocaleLowerCase().includes(inputValue);
                  });
        return items.toArray();
    };
    const onSuggestionsFetchRequested = (object: Autosuggest.SuggestionsFetchRequestedParams): void =>
        setTeamCodes(List(getSuggestions(object.value)));

    const isDataReady = (): boolean => description != null && objectID != null;

    const createTicket = async (): Promise<void> => {
        setLoading(true);
        const id = await getTicketIDAndUpdate();
        const ticket = new Ticket({
            category,
            problem,
            description,
            objectID,
            creatorID: props.currentUser?.id,
            creationDate: new Date(),
            id: `2020-${id}`,
            clientType: props.currentUser?.type
        });
        try {
            await props.postTicket(ticket);
            const notification = new Notification({
                type: NotificationType.newTicket,
                text: `${props.currentUser?.name} creo un nuevo ticket con id ${ticket.id}`
            });
            await props.postNotification(notification);
            setLoading(false);
            setRedirect(true);
        } catch (error) {
            setLoading(false);
            alert(error.message);
        }
    };

    return redirect ? (
        <Redirect to='/dashboard' />
    ) : (
        <Page>
            <PageHeader title='Crear Ticket'>
                <PageHeaderItem title='Dashboard' link='/dashboard' active={false} />
                <PageHeaderItem title='Crear Ticket' link='/create_ticket' active={true} />
            </PageHeader>
            <Card>
                <Card.Header>
                    <Card.Title>Nueva Solicitud</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col className='pt-4' xs={12}>
                            <h3>Reporte</h3>
                            <Row>
                                <Col className='pb-4' xs={12} md={4}>
                                    <span className='mb-2'>Categoría</span>
                                    <SelectInput
                                        options={categories.toArray()}
                                        onSelect={onCategoryChange}
                                        className='custom-select w-100'
                                    />
                                </Col>

                                <Col className='pb-4' xs={12} md={4}>
                                    <span className='mb-2'>Incidencia</span>
                                    <SelectInput
                                        options={getProblemsForCategory(category)}
                                        onSelect={onProblemChange}
                                        className='custom-select'
                                    />
                                </Col>

                                <Col className='pb-4' xs={12} md={4}>
                                    <span className='mb-2'>Número de equipo</span>
                                    <Autosuggest
                                        suggestions={teamCodes.toArray()}
                                        getSuggestionValue={getSuggestionValue}
                                        getSectionSuggestions={getSuggestions}
                                        inputProps={{
                                            placeholder: 'No. De Equipo',
                                            required: true,
                                            onChange: objectIDChange,
                                            className: 'custom-select',
                                            value: objectID != null ? objectID : ''
                                        }}
                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                        renderSuggestion={renderSuggestion}
                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className='py-4' xs={12}>
                            <h3>Comentarios</h3>
                            <Input
                                type='text'
                                placeholder='Comentarios'
                                required
                                as='textarea'
                                onTextChange={onDescriptionChange}
                            />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <LoadingButton
                        block={true}
                        variant='success'
                        spinnerVariant='light'
                        disabled={!isDataReady()}
                        loading={loading}
                        onClick={createTicket}
                    >
                        Enviar Solicitud
                    </LoadingButton>
                </Card.Footer>
            </Card>
        </Page>
    );
};

const mapStateToProps = (state: AppState): ReduxProps => ({
    currentUser: state.users.currentUser,
    teamCodes: state.teamCodes.toList()
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<void, AppState, PostNotificationActionType | SaveTicketActionType>
): ReduxFunctions => ({
    postTicket: async (ticket: Ticket): Promise<void> => {
        await dispatch(postTicketThunk(ticket));
    },
    postNotification: async (notification: Notification): Promise<void> => {
        await dispatch(postNotificationThunk(notification));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientTicketCreator);
