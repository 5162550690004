import { saveFirebaseUser } from '../../actionCreators/authentication';
import firebase from 'firebase/app';
import 'firebase/auth';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { SaveFirebaseUserActionType } from '../../actions/authentication';

const subscribeForUserThunk = () => (
    dispatch: ThunkDispatch<Promise<void>, AppState, SaveFirebaseUserActionType>
): firebase.Unsubscribe => {
    return firebase.auth().onAuthStateChanged(user => {
        dispatch(saveFirebaseUser(user));
    });
};

export default subscribeForUserThunk;
