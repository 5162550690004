import { saveCurrentUser } from '../../actionCreators/users';
import UserRecord from '../../models/User';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { firestoreStructure } from '../../models/FirebaseStructure';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { SaveCurrentUserActionType } from '../../actions/users';

const subscribeForCurrentUserThunk = (id: string) => (
    dispatch: ThunkDispatch<void, AppState, SaveCurrentUserActionType>
): void => {
    firebase
        .firestore()
        .collection(firestoreStructure.Users.name)
        .doc(id)
        .onSnapshot(userDocument => {
            if (userDocument.exists) {
                const user = new UserRecord(userDocument.data());
                if (user.disabled) {
                    firebase.auth().signOut();
                    window.location.reload();
                } else {
                    dispatch(saveCurrentUser(user));
                }
            }
        });
};

export default subscribeForCurrentUserThunk;
