import * as React from 'react';
import Page from '../Page';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { AppState } from '../../redux/reducers';
import TeamCode from '../../redux/models/TeamCode';
import { Map, List } from 'immutable';
import saveTeamCodeThunk from '../../redux/thunks/TeamCodes/saveTeamCodeThunk';
//import saveMultipleCodes from '../../redux/thunks/TeamCodes/saveMultipleCodes';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import LoadingButton from '../LoadingButton';
import SuperadminTeamCodeRow from './SuperadminTeamCodeRow';

interface ReduxProps {
    teamCodes: Map<string, TeamCode>;
}

interface ReduxFunctions {
    saveTeamCode: (teamCode: TeamCode) => Promise<void>;
}

interface Props extends ReduxProps, ReduxFunctions {}

interface State {
    loading: boolean;
    filteredTeamCodes: List<TeamCode>;
    filterText: string;
}

class SuperadminTeamCodes extends React.PureComponent<Props, State> {
    state: Readonly<State> = {
        loading: false,
        filteredTeamCodes: List(),
        filterText: ''
    };

    public componentDidMount(): void {
        this.filterTeamCodes();
        // this.createCodes();
    }

    // public createCodes = async (): Promise<void> => {
    //     const x = codes.map((code, index) => {
    //         const teamCode = new TeamCode({
    //             id: index.toString() + Date.now().toString(),
    //             value: code
    //         });
    //         return teamCode;
    //     });
    //     saveMultipleCodes(x)
    //         .then(() => console.log('ya quedo'))
    //         .catch(error => console.log(error));
    // };

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        if (prevProps.teamCodes !== this.props.teamCodes || prevState.filterText !== this.state.filterText) {
            this.filterTeamCodes();
        }
    }

    public render(): React.ReactNode {
        return (
            <Page>
                <Row>
                    <Col xs={4} />
                    <Col className='d-flex justify-content-center mt-3' xs={4}>
                        <LoadingButton onClick={this.createTeamCode} loading={this.state.loading}>
                            Crear equipo
                        </LoadingButton>
                    </Col>
                    <Col xs={4} />
                </Row>
                <div className='card mt-3'>
                    <div className='card-header d-flex justify-content-between'>
                        <h3 className='card-title'>Lista de equipos</h3>
                    </div>
                    <div className='card-body'>
                        <input
                            placeholder='Buscar...'
                            value={this.state.filterText}
                            onChange={this.updateFilterText}
                            className='w-100 my-5 py-2 px-5 text-white'
                            style={{ border: 'none', backgroundColor: '#343b4a' }}
                            type='text'
                        />
                        <div className='panel-group' id='accordion' role='tablist' aria-multiselectable='true'>
                            <div className='panel panel-default active'>
                                <div className='panel-heading ' role='tab' id='headingOne'></div>
                                <div className='panel-collapse collapse show'>
                                    <div className='panel-body'>
                                        <div
                                            className='table-responsive content vscroll'
                                            style={{ maxHeight: '500px' }}
                                        >
                                            <table className='table card-table table-vcenter text-nowrap'>
                                                <thead>
                                                    <tr>
                                                        <th className='w-50'>Nombre</th>
                                                        <th className='w-50'>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.filteredTeamCodes.map(teamCode => (
                                                        <SuperadminTeamCodeRow key={teamCode.id} teamCode={teamCode} />
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }

    private createTeamCode = async (): Promise<void> => {
        this.setState({ loading: true });
        const value = prompt('Nombre del equipo:');
        if (value != null) {
            const teamCode = new TeamCode({
                value
            });
            await this.props.saveTeamCode(teamCode);
        }
        this.setState({ loading: false });
    };

    private updateFilterText = (event: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({ filterText: event.target.value });

    private filterTeamCodes = () => {
        if (this.state.filterText === '') {
            this.setState({ filteredTeamCodes: this.props.teamCodes.toList() });
        } else {
            const filteredTeamCodes = this.props.teamCodes
                .toList()
                .filter(teamCode => teamCode.value.includes(this.state.filterText));
            this.setState({ filteredTeamCodes });
        }
    };
}

const mapStateToProps = (state: AppState): ReduxProps => ({
    teamCodes: state.teamCodes
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>): ReduxFunctions => ({
    saveTeamCode: async (teamCode: TeamCode) => {
        await dispatch(saveTeamCodeThunk(teamCode));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperadminTeamCodes);
