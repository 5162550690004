import { getAllTickets } from '../../actionCreators/dateTickets';
import dateTicket from '../../models/dateTicket';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import { Map } from 'immutable';
import parseTicketFromDocument from './parseTicketFromDocument';

const subscribeForDateTicketsThunk = (startDate: Date, endDate: Date) => (dispatch: any) => {
    return firebase
        .firestore()
        .collection(firestoreStructure.Tickets.name)
        .where('creationDate', '>', startDate)
        .where('creationDate', '<', endDate)
        .onSnapshot(ticketsData => {
            let tickets = Map<string, dateTicket>();
            ticketsData.docs.forEach(ticketDocument => {
                const ticket = parseTicketFromDocument(ticketDocument);
                tickets = tickets.set(ticket.id, ticket);
            });
            dispatch(getAllTickets(tickets));
        });
};

export default subscribeForDateTicketsThunk;
