import React from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

interface Props {
    title: string;
    value?: number;
    icon: string;
    color: string;
    size: number;
    to: string;
}

const SuperAdminColumnButton: React.FunctionComponent<Props> = (props: Props) => (
    <Col xs={12} lg={props.size}>
        <Link to={props.to}>
            <Card className={`widget-info`} style={{ cursor: 'pointer', backgroundColor: `${props.color}` }}>
                <Card.Body>
                    <Row>
                        <Col className='text-white'>
                            <h5>{props.title}</h5>
                            <h1 className='font-weight-bold'>{props.value}</h1>
                        </Col>
                        <Col className='text-right' style={{ maxWidth: '30%' }}>
                            <i className={`fa ${props.icon} fa-3x text-white`}></i>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Link>
    </Col>
);

export default React.memo(SuperAdminColumnButton);
