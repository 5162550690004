import React from 'react';
import { connect } from 'react-redux';
import LoadingButton from './LoadingButton';
import Input from './Input';
import Page from './Page';
import User from '../redux/models/User';
import { AppState } from '../redux/reducers';
import putUserThunk from '../redux/thunks/Users/putUserThunk';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

interface ReduxProps {
    currentUser: User | undefined;
}

interface ReduxFunctions {
    updateUser: (user: User) => Promise<void>;
}

interface Props extends ReduxProps, ReduxFunctions {}

interface State {
    email: string;
    name: string;
    lastname: string;
    loading: boolean;
}

class Profile extends React.PureComponent<Props, State> {
    public state: Readonly<State> = {
        loading: false,
        email: this.props.currentUser != null ? this.props.currentUser.email : '',
        name: this.props.currentUser != null ? this.props.currentUser.name : '',
        lastname: this.props.currentUser != null ? this.props.currentUser.lastname : ''
    };

    public render() {
        return (
            <Page>
                <div className='row pt-5'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>Editar Perfil</h3>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Nombre(s)</label>
                                            <Input
                                                defaultValue={this.state.name}
                                                onTextChange={this.onNameChange}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>Apellido</label>
                                            <Input
                                                defaultValue={this.state.lastname}
                                                onTextChange={this.onLastnameChange}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>Email</label>
                                            <Input
                                                defaultValue={this.state.email}
                                                onTextChange={this.onEmailChange}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer'>
                                <LoadingButton
                                    onClick={this.update}
                                    variant='primary'
                                    className='w-100'
                                    loading={this.state.loading}
                                >
                                    <i className='fa fa-check'></i> Actualizar Perfil
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }

    private onNameChange = (name: string): void => this.setState({ name });
    private onLastnameChange = (lastname: string): void => this.setState({ lastname });
    private onEmailChange = (email: string): void => this.setState({ email });

    private update = async (): Promise<void> => {
        if (
            this.props.currentUser != null &&
            this.state.email !== '' &&
            this.state.lastname !== '' &&
            this.state.name !== ''
        ) {
            this.setState({
                loading: true
            });
            const newUser = this.props.currentUser.withMutations(user => {
                user.set('email', this.state.email);
                user.set('name', this.state.name);
                user.set('lastname', this.state.lastname);
            });
            this.props.updateUser(newUser);
            this.setState({
                loading: false
            });
        }
    };
}

const mapStateToProps = (state: AppState): ReduxProps => ({
    currentUser: state.users.currentUser
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, Action>): ReduxFunctions => ({
    updateUser: async (user: User): Promise<void> => {
        await dispatch(putUserThunk(user));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
