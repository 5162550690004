import React from 'react';
import HelpDeskUnassignedTicketHeader from './HelpDeskUnassignedTicketHeader';
import Card from 'react-bootstrap/Card';
import TableContainer from '../TableContainer';
import Table from '../Table';

interface Props {
    title: string;
    children: React.ReactNode;
}

const HelpDeskMainTicketsTable: React.FunctionComponent<Props> = (props: Props) => (
    <Card>
        <Card.Header>
            <Card.Title>Tickets Recientes</Card.Title>
        </Card.Header>
        <Card.Body>
            <TableContainer>
                <Table>
                    <HelpDeskUnassignedTicketHeader />
                    {props.children}
                </Table>
            </TableContainer>
        </Card.Body>
    </Card>
);

export default React.memo(HelpDeskMainTicketsTable);
