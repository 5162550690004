import React from 'react';
import LoadingButton from '../LoadingButton';
import Ticket from '../../redux/models/Ticket';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import putTicketThunk from '../../redux/thunks/Tickets/putTicketThunk';
import { connect } from 'react-redux';
import User from '../../redux/models/User';
import TableRowText from '../TableRowText';
import TableRowStatus, { StatusType } from '../TableRowStatus';
import Button from 'react-bootstrap/Button';
import { SaveTicketActionType } from '../../redux/actions/tickets';

interface ReduxProps {
    technician: User | undefined | null;
}

interface ReduxFunctions {
    putTicket: (ticket: Ticket) => Promise<void>;
}

interface ExternalProps {
    ticket: Ticket;
    currentUser: User;
}

interface Props extends ReduxProps, ReduxFunctions, ExternalProps {}

const TicketClientRow: React.FunctionComponent<Props> = (props: Props) => {
    const { ticket } = props;

    const markRecidivism = (): void => {
        const newTicket = props.ticket.withMutations(ticket => {
            ticket.set('recidivism', true);
            ticket.set('closed', false);
            ticket.set('technicianID', null);
        });
        props.putTicket(newTicket);
    };
    const validateTicket = (): void => {
        const validationComment = prompt('Describa el comentario de validación:');
        if (validationComment) {
            const newTicket = props.ticket.withMutations(ticket => {
                ticket.set('verified', true);
                ticket.set('validationComment', validationComment);
                ticket.set('validator', props.currentUser.id);
            });
            props.putTicket(newTicket);
        }
    };

    return (
        <tr>
            <TableRowText text={ticket.id} />
            <TableRowText text={ticket.category} />
            <TableRowText text={ticket.problem} />
            <TableRowText text={ticket.objectID} />
            <TableRowText text={ticket.priority} />

            {ticket.paused ? (
                <TableRowStatus status={StatusType.warning} text='Pausado' />
            ) : ticket.closed ? (
                ticket.technicianID != null ? (
                    <TableRowStatus status={StatusType.success} text='Completado' />
                ) : (
                    <TableRowStatus status={StatusType.error} text='Cancelado' />
                )
            ) : (
                <TableRowStatus status={StatusType.warning} text='En proceso' />
            )}

            <td>
                {props.ticket.verified ? (
                    <Button variant='secondary'>
                        <i className='fa fa-check' /> Validado
                    </Button>
                ) : null}
                {props.ticket.closed && !props.ticket.verified ? (
                    <>
                        <LoadingButton variant='primary' className='mr-3' onClick={validateTicket}>
                            <i className='fa fa-check' /> Validar
                        </LoadingButton>
                        <LoadingButton variant='danger' onClick={markRecidivism}>
                            <i className='fa fa-exclamation' /> Re-Incidencia
                        </LoadingButton>
                    </>
                ) : null}
            </td>
            <TableRowText text={ticket.closeComment} />
        </tr>
    );
};

const mapStateToProps = (state: AppState, ownProps: ExternalProps): ReduxProps => {
    const { technicianID } = ownProps.ticket;
    return {
        technician: technicianID != null ? state.users.technicians.get(technicianID) : undefined
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, SaveTicketActionType>): ReduxFunctions => {
    return {
        putTicket: async (ticket: Ticket): Promise<void> => {
            await dispatch(putTicketThunk(ticket));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TicketClientRow));
