import { combineReducers } from 'redux';
import users, { UsersState } from './users';
import authentication, { AuthenticationState } from './authentication';
import tickets, { TicketsState } from './tickets';
import dateTickets, { dateTicketsState } from './dateTickets';
import notifications, { NotificationsState } from './notifications';
import teamCodes, { TeamCodesState } from './teamCodes';

export interface AppState {
    users: UsersState;
    authentication: AuthenticationState;
    tickets: TicketsState;
    dateTickets: dateTicketsState;
    notifications: NotificationsState;
    teamCodes: TeamCodesState;
}

const rootReducer = combineReducers({
    users,
    authentication,
    tickets,
    dateTickets,
    notifications,
    teamCodes
});

export default rootReducer;
