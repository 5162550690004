import React from 'react';
import subscribeForAllTeamCodesThunk from '../redux/thunks/TeamCodes/subscribeForAllTeamCodesThunk';
import { AppState } from '../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface Props {
    subscribeForAllTeamCodesThunk: () => Promise<void>;
    children: Array<JSX.Element>;
}

class TeamCodes extends React.PureComponent<Props> {
    public componentDidMount() {
        this.props.subscribeForAllTeamCodesThunk();
    }

    public render() {
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    subscribeForAllTeamCodesThunk: async () => {
        await dispatch(subscribeForAllTeamCodesThunk());
    }
});

export default connect(undefined, mapDispatchToProps)(TeamCodes);
