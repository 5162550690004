import React from 'react';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Ticket from '../../redux/models/Ticket';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
//import PageHeader from '../PageHeader';
import TicketRow from './TicketRow';

interface Props {
    tickets: Map<string, Ticket>;
}
class TechnicianDashboard extends React.PureComponent<Props> {
    public render() {
        return (
            <div className='page'>
                <div className='page-main'>
                    <div className='px-3'>
                        {/* <PageHeader
                            title='Dashboard'
                            items={[
                                {
                                    title: 'Dashboard',
                                    link: '/dashboard',
                                    active: true
                                }
                            ]}
                        /> */}
                        <div className='row row-cards'>
                            <div className='col-lg-6 col-md-12 m-b-3'>
                                <Link to='/closed_tickets'>
                                    <div className='widget-info card bg-orange'>
                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className='col-6 text-white'>
                                                    <p>Tickets resueltos</p>
                                                    <h2 className='font-weight-bold'>
                                                        {this.props.tickets.filter(ticket => ticket.closed).size}
                                                    </h2>
                                                </div>
                                                <div className='col-6 m-t-2 text-right'>
                                                    <i className='fa fa-user-times fa-3x text-white'></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <h4 className='page-title'>Tickets abiertos</h4>
                        <div className='row row-cards'>
                            {this.props.tickets
                                .toList()
                                .filter(ticket => !ticket.closed)
                                .map(ticket => (
                                    <TicketRow key={ticket.id} ticket={ticket} />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    tickets: state.tickets
});

export default connect(mapStateToProps)(TechnicianDashboard);
