import { getAllUsers } from '../../actionCreators/users';
import User from '../../models/User';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import { Map } from 'immutable';

const subscribeForAllUsersThunk = () => (dispatch: any) => {
    return firebase
        .firestore()
        .collection(firestoreStructure.Users.name)
        .onSnapshot(usersData => {
            let users = Map<string, User>();
            usersData.docs.forEach(userDocument => {
                const user = new User(userDocument.data());
                users = users.set(user.id, user);
            });
            dispatch(getAllUsers(users));
        });
};

export default subscribeForAllUsersThunk;
