import { getAllTeamCodes } from '../../actionCreators/teamCodes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import { Map } from 'immutable';
import TeamCode from '../../models/TeamCode';

interface TeamCodeData {
    [key: string]: TeamCode;
}

const subscribeForAllTeamCodesThunk = () => (dispatch: any) => {
    return firebase
        .firestore()
        .collection(firestoreStructure.Preferences.name)
        .doc('shared')
        .onSnapshot(snapshot => {
            let teamCodes = Map<string, TeamCode>();
            const teamCodesSnapshotData = snapshot.data();
            if (teamCodesSnapshotData != null) {
                const teamCodesData = teamCodesSnapshotData.teamCodes as TeamCodeData;
                if (teamCodesData != null) {
                    Object.keys(teamCodesData).forEach(key => {
                        teamCodes = teamCodes.set(key, new TeamCode({ id: key, value: teamCodesData[key].value }));
                    });
                }
                dispatch(getAllTeamCodes(teamCodes));
            }
        });
};

export default subscribeForAllTeamCodesThunk;
