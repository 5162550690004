import RouteRecord from '../Navigation/Route';
import TechnicianDashboard from './TechnicianDashboard';
import TechnicianClosedTickets from './TechnicianClosedTickets';

export const dashboard = new RouteRecord({
    path: '/dashboard',
    component: TechnicianDashboard,
    exact: true
});

export const closedTickets = new RouteRecord({
    path: '/closed_tickets',
    component: TechnicianClosedTickets,
    exact: true
});
