import React from 'react';
import subscribeForAllUsersOfType from '../../redux/thunks/Users/subscribeForAllUsersOfType';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { UserType } from '../../redux/models/User';
import { Action } from 'redux';

interface ReduxProps {
    subscribeForAllHelpDesks: () => Promise<void>;
}

interface Props extends ReduxProps {
    children: React.ReactNode;
}

class HelpDesks extends React.PureComponent<Props> {
    public componentDidMount(): void {
        this.props.subscribeForAllHelpDesks();
    }

    public render(): React.ReactNode {
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, Action>): ReduxProps => ({
    subscribeForAllHelpDesks: async (): Promise<void> => {
        await dispatch(subscribeForAllUsersOfType(UserType.helpDesk));
    }
});

export default connect(undefined, mapDispatchToProps)(HelpDesks);
