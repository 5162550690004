import React from 'react';
import '../styles/App.scss';
import { StoreType } from '../redux/store';
import { Provider } from 'react-redux';
import Router from './Navigation/Router';
import Firebase from './Firebase/Firebase';
import Authentication from './Authentication/Authentication';

interface Props {
    store: StoreType;
}

const App: React.FunctionComponent<Props> = (props: Props) => (
    <div id='App'>
        <Provider store={props.store}>
            <Firebase>
                <Authentication>
                    <Router />
                </Authentication>
            </Firebase>
        </Provider>
    </div>
);

export default React.memo(App);
