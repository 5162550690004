import { Record } from 'immutable';

export interface PauseReasonData {
    id: string;
    creationDate: Date;
    reason: string;
    endDate: Date;
}

const PauseReasonRecord = Record<PauseReasonData>({
    id: '',
    creationDate: new Date(),
    reason: '',
    endDate: new Date()
});

export default class PauseReason extends PauseReasonRecord {}
