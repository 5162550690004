import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import Ticket from '../../redux/models/Ticket';
import PageHeader from '../PageHeader';
import ClientColumnButton from './ClientColumnButton';
import TicketClientRow from './ClientTicketRow';
import User from '../../redux/models/User';
import Page from '../Page';
import PageHeaderItem from '../PageHeaderItem';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import TableContainer from '../TableContainer';
import Table from '../Table';
import ClientTicketHeader from './ClientTicketHeader';

interface Props {
    tickets: Array<Ticket>;
    currentUser: User | undefined;
}

const ClientDashboard: React.FunctionComponent<Props> = (props: Props) => {
    const { currentUser } = props;
    return (
        <Page>
            <PageHeader title='Dashboard'>
                <PageHeaderItem title='Dashboard' link='/dashboard' active={true} />
            </PageHeader>
            <Row>
                <ClientColumnButton title='Crear Solicitud' link='/create_ticket' colorClass='bg-blue' />
            </Row>
            <Card>
                <Card.Header>
                    <Card.Title>Tickets abiertos</Card.Title>
                </Card.Header>
                <Card.Body>
                    <TableContainer>
                        <Table>
                            <ClientTicketHeader />
                            <tbody>
                                {currentUser == null
                                    ? null
                                    : props.tickets.map(ticket => (
                                          <TicketClientRow ticket={ticket} key={ticket.id} currentUser={currentUser} />
                                      ))}
                            </tbody>
                        </Table>
                    </TableContainer>
                </Card.Body>
            </Card>
        </Page>
    );
};

const mapStateToProps = (state: AppState): Props => ({
    tickets: state.tickets
        .toList()
        .toArray()
        .sort((ticketOne, ticketTwo) => (parseInt(ticketOne.id.slice(4)) < parseInt(ticketTwo.id.slice(4)) ? -1 : 1)),
    currentUser: state.users.currentUser
});

export default connect(mapStateToProps)(React.memo(ClientDashboard));
