import RouteRecord from '../Navigation/Route';
import SuperadminDashboard from './SuperadminDashboard';
import SuperadminUserCreator from './SuperadminUserCreator';
import SuperadminTeamCodes from './SuperadminTeamCodes';
import SuperadminGraph from './SuperadminGraph';

export const dashboard = new RouteRecord({
    path: '/dashboard',
    component: SuperadminDashboard,
    exact: true
});

export const graficas = new RouteRecord({
    path: '/home',
    component: SuperadminGraph,
    exact: true
});

export const createUser = new RouteRecord({
    path: '/create_user',
    component: SuperadminUserCreator,
    exact: true
});

export const teamCodes = new RouteRecord({
    path: '/team_codes',
    component: SuperadminTeamCodes,
    exact: true
});
