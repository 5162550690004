import {
    GetAllNotificationsType,
    GetAllNotificationsAction,
    PostNotificationAction,
    PostNotificationActionType
} from '../actions/notifications';
import { Map } from 'immutable';
import Notification from '../models/Notification';

const initialState = Map<string, Notification>();

export type NotificationsState = typeof initialState;

export default (state = initialState, action: GetAllNotificationsType | PostNotificationActionType) => {
    switch (action.type) {
        case GetAllNotificationsAction:
            return state.concat(action.notifications);
        case PostNotificationAction:
            return state.set(action.notification.id, action.notification);
        default:
            return state;
    }
};
