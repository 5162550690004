import React from 'react';

interface Props {
    children: React.ReactNode;
}

const TableContainer: React.FunctionComponent<Props> = (props: Props) => (
    <div className='panel-group' id='accordion' role='tablist' aria-multiselectable='true'>
        <div className='panel panel-default active'>
            <div className='panel-heading ' role='tab' id='headingOne'></div>
            <div id='collapseOne' className='panel-collapse collapse show' role='tabpanel' aria-labelledby='headingOne'>
                <div className='panel-body'>
                    <div className='table-responsive content vscroll'>{props.children}</div>
                </div>
            </div>
        </div>
    </div>
);

export default TableContainer;
