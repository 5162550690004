import React from 'react';
import Ticket from '../../redux/models/Ticket';
import { AppState } from '../../redux/reducers';
import User from '../../redux/models/User';
import { connect } from 'react-redux';

interface Props {
    ticket: Ticket;
    client?: User;
    technician?: User;
}

const TicketOverviewRow: React.FunctionComponent<Props> = props => {
    const { ticket, technician, client } = props;
    return (
        <tr>
            <td>
                <span className='text-muted'>{ticket.id}</span>
            </td>
            <td>
                <span className='text-muted'>{ticket.creationDate.toUTCString()}</span>
            </td>
            <td>
                <span className='text-muted'>{ticket.creationDate.toUTCString()}</span>
            </td>
            <td>
                <span className='text-muted'>{client != null ? `${client.name} ${client.lastname}` : ''}</span>
            </td>
            <td>
                <span className='text-muted'>{ticket.category}</span>
            </td>
            <td>
                <span className='text-muted'>{ticket.problem}</span>
            </td>
            <td className='text-muted'>{technician != null ? `${technician.name} ${technician.lastname}` : ''}</td>
            <td>
                {ticket.closed ? (
                    <>
                        <span className='status-icon bg-success'></span>
                        <span className='text-muted'>Cerrado</span>
                    </>
                ) : (
                    <>
                        <span className='status-icon bg-warning'></span>
                        <span className='text-muted'>En proceso</span>
                    </>
                )}
            </td>
            <td>
                <span className='text-muted'>{ticket.priority}</span>
            </td>
        </tr>
    );
};

const mapStateToProps = (state: AppState, ownProps: Props) => ({
    client: state.users.clients.get(ownProps.ticket.creatorID),
    technician:
        ownProps.ticket.technicianID != null ? state.users.technicians.get(ownProps.ticket.technicianID) : undefined
});

export default connect(mapStateToProps)(TicketOverviewRow);
