import React from 'react';
import subscribeForAllClientTickets from '../../redux/thunks/Tickets/subscribeForAllClientTickets';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import User, { UserType } from '../../redux/models/User';

interface ReduxProps {
    currentUser: User | undefined;
}

interface Props extends ReduxProps {
    subscribeForAllClientTickets: (clientType: UserType.clientT1 | UserType.clientT2) => Promise<void>;
    children: JSX.Element;
}

class Tickets extends React.PureComponent<Props> {
    public componentDidMount() {
        if (
            this.props.currentUser != null &&
            (this.props.currentUser.type === UserType.clientT1 || this.props.currentUser.type === UserType.clientT2)
        ) {
            this.props.subscribeForAllClientTickets(this.props.currentUser.type);
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps !== this.props) {
            if (
                this.props.currentUser != null &&
                (this.props.currentUser.type === UserType.clientT1 || this.props.currentUser.type === UserType.clientT2)
            ) {
                this.props.subscribeForAllClientTickets(this.props.currentUser.type);
            }
        }
    }

    public render() {
        return this.props.children;
    }
}

const mapStateToProps = (state: AppState): ReduxProps => ({
    currentUser: state.users.currentUser
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    subscribeForAllClientTickets: async (clientType: UserType.clientT1 | UserType.clientT2) => {
        await dispatch(subscribeForAllClientTickets(clientType));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
