import React from 'react';
import Row from 'react-bootstrap/Row';
import HelpDeskMainTicketHeader from './HelpDeskMainTicketHeader';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import TableContainer from '../TableContainer';
import Table from '../Table';

interface Props {
    title: string;
    children: React.ReactNode;
}

const HelpDeskMainTicketsTable: React.FunctionComponent<Props> = (props: Props) => (
    <Row>
        <Col>
            <Card>
                <Card.Header>
                    <Card.Title>Tickets Recientes</Card.Title>
                </Card.Header>
                <Card.Body>
                    <TableContainer>
                        <Table>
                            <HelpDeskMainTicketHeader />
                            {props.children}
                        </Table>
                    </TableContainer>
                </Card.Body>
            </Card>
        </Col>
    </Row>
);

export default React.memo(HelpDeskMainTicketsTable);
