import { saveTeamCode } from '../../actionCreators/teamCodes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import TeamCode from '../../models/TeamCode';
import store from '../../store';

const updateTeamCodeThunk = (teamCode: TeamCode) => async (dispatch: any): Promise<void> => {
    let teamCodes = store.getState().teamCodes;
    teamCodes = teamCodes.set(teamCode.id, teamCode);
    dispatch(saveTeamCode(teamCode));
    const teamCodesDocument = firebase
        .firestore()
        .collection(firestoreStructure.Preferences.name)
        .doc('shared');
    await teamCodesDocument.update({ teamCodes: teamCodes.toJS() });
};

export default updateTeamCodeThunk;
