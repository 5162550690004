import React from 'react';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import SuperAdminColumnButton from './SuperAdminColumnButton';
import Ticket from '../../redux/models/Ticket';
import dateTicket from '../../redux/models/dateTicket';

import { Bar } from 'react-chartjs-2';
//import PauseReason from '../../redux/models/PauseReason';

interface Props {
    tickets: Map<string, Ticket>;
    dateTickets: Map<string, dateTicket>;
}

const SuperadminGraph: React.FunctionComponent<Props> = (props: Props) => {
    const { tickets, dateTickets } = props;
    //let closedTicketsAll = 0;
    let closedTicketsMonth = 0;

    let todayTickets = 0;
    let ticketsFueraTiempoCount = 0;
    let ticketsFueraTiempo: any = [];

    //var meanTimeAllTickets = 0;
    var meanTimeMonthTickets = 0;

    let dia = '';

    let ticketAbiertoSemana1 = 0,
        ticketCerradoSemana1 = 0,
        ticketPausadoSemana1 = 0;
    let ticketAbiertoSemana2 = 0,
        ticketCerradoSemana2 = 0,
        ticketPausadoSemana2 = 0;
    let ticketAbiertoSemana3 = 0,
        ticketCerradoSemana3 = 0,
        ticketPausadoSemana3 = 0;
    let ticketAbiertoSemana4 = 0,
        ticketCerradoSemana4 = 0,
        ticketPausadoSemana4 = 0;
    let ticketAbiertoSemana5 = 0,
        ticketCerradoSemana5 = 0,
        ticketPausadoSemana5 = 0;
    let nombreDia = '';

    let ticketAbiertoLunes = 0;
    let ticketPausadoLunes = 0;
    let ticketCerradoLunes = 0;
    let ticketAbiertoMartes = 0;
    let ticketPausadoMartes = 0;
    let ticketCerradoMartes = 0;
    let ticketAbiertoMiercoles = 0;
    let ticketPausadoMiercoles = 0;
    let ticketCerradoMiercoles = 0;
    let ticketAbiertoJueves = 0;
    let ticketPausadoJueves = 0;
    let ticketCerradoJueves = 0;
    let ticketAbiertoViernes = 0;
    let ticketPausadoViernes = 0;
    let ticketCerradoViernes = 0;
    let ticketAbiertoSabado = 0;
    let ticketPausadoSabado = 0;
    let ticketCerradoSabado = 0;
    let ticketAbiertoDomingo = 0;
    let ticketPausadoDomingo = 0;
    let ticketCerradoDomingo = 0;
    const ticketsArrayAll = tickets
        .toList()
        .toArray()
        .sort((ticketOne, ticketTwo) => (parseInt(ticketOne.id.slice(4)) < parseInt(ticketTwo.id.slice(4)) ? 1 : -1));

    const ticketsArray = dateTickets
        .toList()
        .toArray()
        .sort((ticketOne, ticketTwo) => (parseInt(ticketOne.id.slice(4)) < parseInt(ticketTwo.id.slice(4)) ? 1 : -1));

    ticketsArray.forEach(ticket => {
        if (ticket.creationDate.getDate() === new Date().getDate()) {
            todayTickets += 1;
        }

        var tiempoTotal = 0;
        var tiempoResolucion = 0;
        var tiempoPausa = 0;

        if (ticket.closeDate && ticket.assignationDate) {
            tiempoResolucion = ticket.closeDate.getTime() - ticket.assignationDate.getTime();

            if (ticket.pauseReasons.count() > 0) {
                tiempoPausa = ticket.pauseReasons
                    .map(date => {
                        return date.endDate.getTime() - date.creationDate.getTime();
                    })
                    .first();
            }
            tiempoTotal = tiempoResolucion - tiempoPausa;

            switch (ticket.priority) {
                case 'Baja (8hrs.)':
                    if (tiempoTotal > 2.88e7) {
                        ticketsFueraTiempoCount += 1;
                        ticketsFueraTiempo.push(ticket);
                    }
                    break;
                case 'Media (6hrs.)':
                    if (tiempoTotal > 2.16e7) {
                        ticketsFueraTiempoCount += 1;
                        ticketsFueraTiempo.push(ticket);
                    }
                    break;
                case 'Alta (4hrs.)':
                    if (tiempoTotal > 1.44e7) {
                        ticketsFueraTiempoCount += 1;
                        ticketsFueraTiempo.push(ticket);
                    }
                    break;
                default:
                    break;
            }
        }

        dia = ticket.creationDate.toString().substring(8, 10);
        nombreDia = ticket.creationDate.toString().substring(0, 3);
        if (parseInt(dia) <= 7) {
            if (ticket.closed) {
                ticketCerradoSemana1 += 1;
                if (new Date().getDate() <= 7) {
                    if (nombreDia === 'Mon') {
                        ticketCerradoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketCerradoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketCerradoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketCerradoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketCerradoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketCerradoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketCerradoDomingo += 1;
                    }
                }
            } else {
                ticketAbiertoSemana1 += 1;
                if (new Date().getDate() <= 7) {
                    if (nombreDia === 'Mon') {
                        ticketAbiertoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketAbiertoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketAbiertoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketAbiertoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketAbiertoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketAbiertoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketAbiertoDomingo += 1;
                    }
                }
                if (ticket.paused && !ticket.closed) {
                    ticketPausadoSemana1 += 1;
                    if (new Date().getDate() <= 7) {
                        if (nombreDia === 'Mon') {
                            ticketPausadoLunes += 1;
                        }
                        if (nombreDia === 'Tue') {
                            ticketPausadoMartes += 1;
                        }
                        if (nombreDia === 'Wed') {
                            ticketPausadoMiercoles += 1;
                        }
                        if (nombreDia === 'Thu') {
                            ticketPausadoJueves += 1;
                        }
                        if (nombreDia === 'Fri') {
                            ticketPausadoViernes += 1;
                        }
                        if (nombreDia === 'Sat') {
                            ticketPausadoSabado += 1;
                        }
                        if (nombreDia === 'Sun') {
                            ticketPausadoDomingo += 1;
                        }
                    }
                }
            }
        } else if (parseInt(dia) >= 8 && parseInt(dia) <= 14) {
            if (ticket.closed) {
                ticketCerradoSemana2 += 1;
                if (new Date().getDate() >= 8 && new Date().getDate() <= 14) {
                    if (nombreDia === 'Mon') {
                        ticketCerradoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketCerradoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketCerradoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketCerradoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketCerradoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketCerradoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketCerradoDomingo += 1;
                    }
                }
            } else {
                ticketAbiertoSemana2 += 1;
                if (new Date().getDate() >= 8 && new Date().getDate() <= 14) {
                    if (nombreDia === 'Mon') {
                        ticketAbiertoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketAbiertoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketAbiertoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketAbiertoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketAbiertoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketAbiertoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketAbiertoDomingo += 1;
                    }
                }
                if (ticket.paused && !ticket.closed) {
                    ticketPausadoSemana2 += 1;
                    if (new Date().getDate() >= 8 && new Date().getDate() <= 14) {
                        if (nombreDia === 'Mon') {
                            ticketPausadoLunes += 1;
                        }
                        if (nombreDia === 'Tue') {
                            ticketPausadoMartes += 1;
                        }
                        if (nombreDia === 'Wed') {
                            ticketPausadoMiercoles += 1;
                        }
                        if (nombreDia === 'Thu') {
                            ticketPausadoJueves += 1;
                        }
                        if (nombreDia === 'Fri') {
                            ticketPausadoViernes += 1;
                        }
                        if (nombreDia === 'Sat') {
                            ticketPausadoSabado += 1;
                        }
                        if (nombreDia === 'Sun') {
                            ticketPausadoDomingo += 1;
                        }
                    }
                }
            }
        } else if (parseInt(dia) >= 15 && parseInt(dia) <= 21) {
            if (ticket.closed) {
                ticketCerradoSemana3 += 1;
                if (new Date().getDate() >= 15 && new Date().getDate() <= 21) {
                    if (nombreDia === 'Mon') {
                        ticketCerradoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketCerradoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketCerradoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketCerradoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketCerradoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketCerradoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketCerradoDomingo += 1;
                    }
                }
            } else {
                ticketAbiertoSemana3 += 1;
                if (new Date().getDate() >= 15 && new Date().getDate() <= 21) {
                    if (nombreDia === 'Mon') {
                        ticketAbiertoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketAbiertoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketAbiertoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketAbiertoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketAbiertoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketAbiertoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketAbiertoDomingo += 1;
                    }
                }
                if (ticket.paused && !ticket.closed) {
                    ticketPausadoSemana3 += 1;
                    if (new Date().getDate() >= 15 && new Date().getDate() <= 21) {
                        if (nombreDia === 'Mon') {
                            ticketPausadoLunes += 1;
                        }
                        if (nombreDia === 'Tue') {
                            ticketPausadoMartes += 1;
                        }
                        if (nombreDia === 'Wed') {
                            ticketPausadoMiercoles += 1;
                        }
                        if (nombreDia === 'Thu') {
                            ticketPausadoJueves += 1;
                        }
                        if (nombreDia === 'Fri') {
                            ticketPausadoViernes += 1;
                        }
                        if (nombreDia === 'Sat') {
                            ticketPausadoSabado += 1;
                        }
                        if (nombreDia === 'Sun') {
                            ticketPausadoDomingo += 1;
                        }
                    }
                }
            }
        } else if (parseInt(dia) >= 22 && parseInt(dia) <= 28) {
            if (ticket.closed) {
                ticketCerradoSemana4 += 1;
                if (new Date().getDate() >= 22 && new Date().getDate() <= 28) {
                    if (nombreDia === 'Mon') {
                        ticketCerradoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketCerradoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketCerradoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketCerradoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketCerradoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketCerradoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketCerradoDomingo += 1;
                    }
                }
            } else {
                ticketAbiertoSemana4 += 1;
                if (new Date().getDate() >= 22 && new Date().getDate() <= 28) {
                    if (nombreDia === 'Mon') {
                        ticketAbiertoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketAbiertoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketAbiertoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketAbiertoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketAbiertoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketAbiertoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketAbiertoDomingo += 1;
                    }
                }

                if (ticket.paused && !ticket.closed) {
                    ticketPausadoSemana4 += 1;
                    if (new Date().getDate() >= 22 && new Date().getDate() <= 28) {
                        if (nombreDia === 'Mon') {
                            ticketPausadoLunes += 1;
                        }
                        if (nombreDia === 'Tue') {
                            ticketPausadoMartes += 1;
                        }
                        if (nombreDia === 'Wed') {
                            ticketPausadoMiercoles += 1;
                        }
                        if (nombreDia === 'Thu') {
                            ticketPausadoJueves += 1;
                        }
                        if (nombreDia === 'Fri') {
                            ticketPausadoViernes += 1;
                        }
                        if (nombreDia === 'Sat') {
                            ticketPausadoSabado += 1;
                        }
                        if (nombreDia === 'Sun') {
                            ticketPausadoDomingo += 1;
                        }
                    }
                }
            }
        } else if (parseInt(dia) > 28) {
            if (ticket.closed) {
                ticketCerradoSemana5 += 1;
                if (new Date().getDate() > 28) {
                    if (nombreDia === 'Mon') {
                        ticketCerradoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketCerradoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketCerradoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketCerradoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketCerradoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketCerradoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketCerradoDomingo += 1;
                    }
                }
            } else {
                ticketAbiertoSemana5 += 1;
                if (new Date().getDate() > 28) {
                    if (nombreDia === 'Mon') {
                        ticketAbiertoLunes += 1;
                    }
                    if (nombreDia === 'Tue') {
                        ticketAbiertoMartes += 1;
                    }
                    if (nombreDia === 'Wed') {
                        ticketAbiertoMiercoles += 1;
                    }
                    if (nombreDia === 'Thu') {
                        ticketAbiertoJueves += 1;
                    }
                    if (nombreDia === 'Fri') {
                        ticketAbiertoViernes += 1;
                    }
                    if (nombreDia === 'Sat') {
                        ticketAbiertoSabado += 1;
                    }
                    if (nombreDia === 'Sun') {
                        ticketAbiertoDomingo += 1;
                    }
                }

                if (ticket.paused && !ticket.closed) {
                    ticketPausadoSemana5 += 1;
                    if (new Date().getDate() > 28) {
                        if (nombreDia === 'Mon') {
                            ticketPausadoLunes += 1;
                        }
                        if (nombreDia === 'Tue') {
                            ticketPausadoMartes += 1;
                        }
                        if (nombreDia === 'Wed') {
                            ticketPausadoMiercoles += 1;
                        }
                        if (nombreDia === 'Thu') {
                            ticketPausadoJueves += 1;
                        }
                        if (nombreDia === 'Fri') {
                            ticketPausadoViernes += 1;
                        }
                        if (nombreDia === 'Sat') {
                            ticketPausadoSabado += 1;
                        }
                        if (nombreDia === 'Sun') {
                            ticketPausadoDomingo += 1;
                        }
                    }
                }
            }
        }

        if (ticket.closed && ticket.closeDate != null) {
            meanTimeMonthTickets += ticket.closeDate.getTime() - ticket.creationDate.getTime();
            closedTicketsMonth += 1;
        }
    });
    meanTimeMonthTickets = convertMS(meanTimeMonthTickets / closedTicketsMonth).hour;

    function convertMS(milliseconds: number) {
        var day, hour, minute, seconds;
        seconds = Math.floor(milliseconds / 1000);
        minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hour = Math.floor(minute / 60);
        minute = minute % 60;
        day = Math.floor(hour / 24);
        hour = hour % 24;
        return {
            day: day,
            hour: hour,
            minute: minute,
            seconds: seconds
        };
    }
    const options = {
        responsive: true,
        legend: {
            display: true,
            position: 'top',
            fullWidth: true,
            labels: {
                boxWidth: 25,
                fontSize: 13,
                padding: 10,
                fontColor: '#ffffff'
            }
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontColor: '#ffffff',
                        fontSize: 13
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: '#ffffff',
                        fontSize: 13
                    }
                }
            ]
        }
    };

    return (
        <div className='container'>
            <div className='row mt-4'>
                <div className='col-lg-1'></div>
                <SuperAdminColumnButton
                    color='#120136'
                    title='Todos los tickets &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                    size={5}
                    value={ticketsArrayAll.length}
                    icon='fa-list-ul'
                    to='#'
                />

                <SuperAdminColumnButton
                    color='#035AA6'
                    title='Tiempo promedio mensual de atención por ticket'
                    size={5}
                    value={isNaN(meanTimeMonthTickets) ? 0 : meanTimeMonthTickets}
                    icon='fa-clock'
                    to='#'
                />
            </div>
            <div className='row'>
                <div className='col-lg-1'></div>
                <SuperAdminColumnButton
                    color='#E71414'
                    title='Incidencias levantadas hoy &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                    size={5}
                    value={todayTickets}
                    icon='fa-exclamation-triangle'
                    to='#'
                />
                <SuperAdminColumnButton
                    color='#FCBF1E'
                    title='Tickets mensuales resueltos fuera de tiempo'
                    size={5}
                    value={ticketsFueraTiempoCount}
                    icon='fa-calendar-times-o'
                    to='#'
                />
            </div>
            <div className='row'>
                <div className='col-lg-1'></div>
                <div className='col-12 col-md-5 mb-5 mt-5'>
                    <div className='form-group col-6'>
                        <h5 className='font-weight-bold'>Gráfica Mensual</h5>
                    </div>
                    <Bar
                        data={{
                            labels: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4', 'Semana 5'],
                            datasets: [
                                {
                                    label: 'Tickets Abiertos',
                                    backgroundColor: '#035AA6',
                                    data: [
                                        ticketAbiertoSemana1,
                                        ticketAbiertoSemana2,
                                        ticketAbiertoSemana3,
                                        ticketAbiertoSemana4,
                                        ticketAbiertoSemana5
                                    ]
                                },
                                {
                                    label: 'Tickets Pausados',
                                    backgroundColor: '#ffd359',
                                    data: [
                                        ticketPausadoSemana1,
                                        ticketPausadoSemana2,
                                        ticketPausadoSemana3,
                                        ticketPausadoSemana4,
                                        ticketPausadoSemana5
                                    ]
                                },
                                {
                                    label: 'Tickets Cerrados',
                                    backgroundColor: '#35c4ba',
                                    data: [
                                        ticketCerradoSemana1,
                                        ticketCerradoSemana2,
                                        ticketCerradoSemana3,
                                        ticketCerradoSemana4,
                                        ticketCerradoSemana5
                                    ]
                                }
                            ]
                        }}
                        options={options}
                    />
                </div>
                <div className='col-12 col-md-5 mb-5 mt-5'>
                    <h5 className='font-weight-bold'>Gráfica Semanal</h5>
                    <Bar
                        data={{
                            labels: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
                            datasets: [
                                {
                                    label: 'Tickets Abiertos',
                                    backgroundColor: '#035AA6',
                                    data: [
                                        ticketAbiertoLunes,
                                        ticketAbiertoMartes,
                                        ticketAbiertoMiercoles,
                                        ticketAbiertoJueves,
                                        ticketAbiertoViernes,
                                        ticketAbiertoSabado,
                                        ticketAbiertoDomingo
                                    ]
                                },
                                {
                                    label: 'Tickets Pausados',
                                    backgroundColor: '#ffd359',
                                    data: [
                                        ticketPausadoLunes,
                                        ticketPausadoMartes,
                                        ticketPausadoMiercoles,
                                        ticketPausadoJueves,
                                        ticketPausadoViernes,
                                        ticketPausadoSabado,
                                        ticketPausadoDomingo
                                    ]
                                },
                                {
                                    label: 'Tickets Cerrados',
                                    backgroundColor: '#35c4ba',
                                    data: [
                                        ticketCerradoLunes,
                                        ticketCerradoMartes,
                                        ticketCerradoMiercoles,
                                        ticketCerradoJueves,
                                        ticketCerradoViernes,
                                        ticketCerradoSabado,
                                        ticketCerradoDomingo
                                    ]
                                }
                            ]
                        }}
                        options={options}
                    />
                </div>
                <div className='col-lg-1'></div>
            </div>
        </div>
    );
};
const mapStateToProps = (state: AppState): Props => ({
    tickets: state.tickets,
    dateTickets: state.dateTickets
});

export default connect(mapStateToProps)(React.memo(SuperadminGraph));
