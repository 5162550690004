import React from 'react';

interface Props {
    children: React.ReactNode;
}

const Table: React.FunctionComponent<Props> = (props: Props) => (
    <table className='table card-table table-vcenter text-nowrap'>{props.children}</table>
);

export default Table;
