import { postNotification } from '../../actionCreators/notifications';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import Notification from '../../models/Notification';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { PostNotificationActionType } from '../../actions/notifications';

const postTicketThunk = (notification: Notification) => async (
    dispatch: ThunkDispatch<void, AppState, PostNotificationActionType>
): Promise<void> => {
    const document = firebase
        .firestore()
        .collection(firestoreStructure.Notifications.name)
        .doc();
    const finalNotification = notification.set('id', document.id);
    await document.set(finalNotification.toJS(), { merge: true });
    dispatch(postNotification(finalNotification));
};

export default postTicketThunk;
