import { saveUser } from '../../actionCreators/users';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import User from '../../models/User';

const putUserThunk = (user: User) => async (dispatch: any) => {
    await firebase
        .firestore()
        .collection(firestoreStructure.Users.name)
        .doc(user.id)
        .update(user.toJS());
    dispatch(saveUser(user));
};

export default putUserThunk;
