import * as React from 'react';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import '../styles/Input.scss';
import getFinalClassname from '../utils/getFinalClassname';
import getTextFromInputEvent from '../utils/getTextFromInputEvent';

export interface Props extends FormControlProps {
    className?: string;
    placeholder?: string;
    onTextChange?: (text: string) => void;
    defaultValue?: string;
    required?: boolean;
    pattern?: string;
}

const Input: React.FunctionComponent<Props> = (props: Props) => {
    const { className, onTextChange, onChange, ...others } = props;
    const handleOnChange = (event: any): void => {
        if (onChange != null) {
            onChange(event);
        }
        if (onTextChange != null) {
            onTextChange(getTextFromInputEvent(event));
        }
    };
    return <FormControl {...others} onChange={handleOnChange} className={getFinalClassname(className, '')} />;
};

export default React.memo(Input);
