import { Record, List } from 'immutable';

export enum UserType {
    superadmin = 'Superadmin',
    supervisor = 'Supervisor',
    technician = 'Tecnico',
    helpDesk = 'Mesa de ayuda',
    clientT1 = 'Cliente terminal 1',
    clientT2 = 'Cliente terminal 2'
}

export const types = List([
    UserType.superadmin,
    UserType.supervisor,
    UserType.technician,
    UserType.helpDesk,
    UserType.clientT1,
    UserType.clientT2
]);

export interface UserData {
    name: string;
    lastname: string;
    id: string;
    email: string;
    type: UserType;
    disabled: boolean;
}

const UserRecord = Record<UserData>({
    name: '',
    lastname: '',
    id: '',
    email: '',
    type: UserType.superadmin,
    disabled: false
});

export default class User extends UserRecord {}
