import React from 'react';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Ticket from '../../redux/models/Ticket';
import './HelpDeskMainTicketRow';
import HelpDeskMainTicketRow from './HelpDeskMainTicketRow';
import Page from '../Page';
import PageHeader from '../PageHeader';
import PageHeaderItem from '../PageHeaderItem';
import HelpDeskMainTicketsTable from './HelpDeskMainTicketsTable';

interface Props {
    tickets: Array<Ticket>;
}

const HelpDeskAllTickes: React.FunctionComponent<Props> = (props: Props) => (
    <Page>
        <PageHeader title='Todos los tickets'>
            <PageHeaderItem title='Dashboard' link='/dashboard' active={false} />
            <PageHeaderItem title='Todos los tickets' link='/all_tickets' active={true} />
        </PageHeader>
        <HelpDeskMainTicketsTable title='Todos los tickets'>
            <tbody>
                {props.tickets.map(ticket => (
                    <HelpDeskMainTicketRow key={ticket.id} ticket={ticket} />
                ))}
            </tbody>
        </HelpDeskMainTicketsTable>
    </Page>
);

const mapStateToProps = (state: AppState): Props => ({
    tickets: state.tickets
        .toList()
        .toArray()
        .sort((ticketOne, ticketTwo) => (parseInt(ticketOne.id.slice(4)) < parseInt(ticketTwo.id.slice(4)) ? 1 : -1))
});

export default connect(mapStateToProps)(React.memo(HelpDeskAllTickes));
