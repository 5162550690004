import React from 'react';
import { BrowserRouter as ReactRouter, Route, Switch } from 'react-router-dom';
import { signIn, signUp, root } from '../Navigation/Routes';

const Router: React.FunctionComponent = () => (
    <ReactRouter>
        <Switch>
            <Route path={signIn.path} component={signIn.component} exact={signIn.exact} />
            <Route path={signUp.path} component={signUp.component} exact={signUp.exact} />
            <Route path={root.path} component={root.component} exact={root.exact} />
        </Switch>
    </ReactRouter>
);

export default React.memo(Router);
