import React, { useEffect } from 'react';
import { User, Unsubscribe } from 'firebase/app';
import subscribeForAuthenticatedUserThunk from '../../redux/thunks/Authentication/subscribeForAuthenticatedUserThunk';
import subscribeForCurrentUserThunk from '../../redux/thunks/Users/subscribeForCurrentUserThunk';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { setLoadingFirebaseData } from '../../redux/actionCreators/authentication';
import { SaveFirebaseUserActionType, LoadingFirebaseDataActionType } from '../../redux/actions/authentication';
import { SaveCurrentUserActionType } from '../../redux/actions/users';

interface ReduxProps {
    firebaseUser: User | undefined | null;
}

interface ReduxFunctions {
    subscribeForAuthenticatedUser: () => Unsubscribe;
    subscribeForCurrentUser: (id: string) => void;
    setLoadingFirebaseData: (loading: boolean) => void;
}

interface Props extends ReduxProps, ReduxFunctions {
    children: React.ReactNode;
}

const Authentication: React.FunctionComponent<Props> = (props: Props) => {
    const { firebaseUser } = props;

    useEffect(() => {
        const unsubscribe = props.subscribeForAuthenticatedUser();

        return (): void => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (firebaseUser === undefined) {
            props.setLoadingFirebaseData(true);
        } else if (firebaseUser !== undefined) {
            props.setLoadingFirebaseData(false);
        }

        props.subscribeForAuthenticatedUser();

        if (firebaseUser != null) {
            props.subscribeForCurrentUser(firebaseUser.uid);
        }
    }, [firebaseUser]);

    return <>{props.children}</>;
};

const mapStateToProps = (state: AppState): ReduxProps => ({
    firebaseUser: state.authentication.firebaseUser
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<
        void | Unsubscribe,
        AppState,
        SaveFirebaseUserActionType | SaveCurrentUserActionType | LoadingFirebaseDataActionType
    >
): ReduxFunctions => ({
    subscribeForAuthenticatedUser: (): Unsubscribe => {
        return dispatch(subscribeForAuthenticatedUserThunk());
    },
    subscribeForCurrentUser: (id: string): void => {
        dispatch(subscribeForCurrentUserThunk(id));
    },
    setLoadingFirebaseData: (loading: boolean): void => {
        dispatch(setLoadingFirebaseData(loading));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
