import TeamCode from '../models/TeamCode';
import { Map } from 'immutable';

export const GetAllTeamCodesAction = 'GET_ALL_TEAM_CODES';
export interface GetAllTeamCodesActionType {
    type: typeof GetAllTeamCodesAction;
    teamCodes: Map<string, TeamCode>;
}

export const SaveTeamCodeAction = 'SAVE_TEAM_CODE';
export interface SaveTeamCodeActionType {
    type: typeof SaveTeamCodeAction;
    teamCode: TeamCode;
}

export const DeleteTeamCodeAction = 'DELETE_TEAM_CODE';
export interface DeleteTeamCodeActionType {
    type: typeof DeleteTeamCodeAction;
    teamCode: TeamCode;
}
