import * as React from 'react';
import FormControl, { FormControlProps } from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

export interface InputProps extends FormControlProps {
    className?: string;
    placeholder?: string;
    onSelect?: (value: string, index: number) => void;
    defaultValue?: string;
    options: Array<string>;
}

const Input: React.FunctionComponent<InputProps> = (props: InputProps) => {
    const { onSelect, options, placeholder, ...others } = props;
    return (
        <InputGroup className='w-100'>
            {placeholder == null ? null : (
                <InputGroup.Prepend>
                    <InputGroup.Text>{placeholder}</InputGroup.Text>
                </InputGroup.Prepend>
            )}
            <FormControl
                {...others}
                as='select'
                onChange={(event: any): void => {
                    if (onSelect != null) {
                        const target = event.target as HTMLSelectElement;
                        onSelect(target.value, props.options.indexOf(target.value));
                    }
                }}
            >
                {options.map(option => (
                    <option key={option}>{option}</option>
                ))}
            </FormControl>
        </InputGroup>
    );
};

export default React.memo(Input);
