import React from 'react';

const HelpDeskMainTicketHeader: React.FunctionComponent = () => (
    <thead>
        <tr>
            <th>ID ticket</th>
            <th>Categoría</th>
            <th>Incidencia</th>
            <th>Equipo</th>
            <th>Cliente</th>
            <th>Fecha y hora de creación</th>
            <th>Fecha y hora de atención</th>
            <th>Fecha y hora de cierre</th>
            <th>Prioridad</th>
            <th>Técnico</th>
            <th>Cliente que validó</th>
            <th>Mesa de ayuda</th>
            <th>Pausar / cerrar ticket</th>
            <th>Motivo de pausa</th>
            <th>Tiempo en pausa</th>
            <th>Comentario al cierre</th>
            <th>Comentario de validación</th>
        </tr>
    </thead>
);

export default React.memo(HelpDeskMainTicketHeader);
