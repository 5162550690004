import { saveTeamCode } from '../../actionCreators/teamCodes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import TeamCode from '../../models/TeamCode';
import store from '../../store';

const saveTeamCodeThunk = (teamCode: TeamCode) => async (dispatch: any): Promise<void> => {
    let teamCodes = store.getState().teamCodes;
    const nextID = parseInt(teamCodes.last<TeamCode>().id) + 1;
    const teamCodesDocument = firebase
        .firestore()
        .collection(firestoreStructure.Preferences.name)
        .doc('shared');
    const finalTeamCode = teamCode.set('id', nextID.toString() + Date.now().toString());
    teamCodes = teamCodes.set(finalTeamCode.id, finalTeamCode);
    await teamCodesDocument.set({ teamCodes: teamCodes.toJS() }, { merge: true });
    dispatch(saveTeamCode(finalTeamCode));
};

export default saveTeamCodeThunk;
