import React from 'react';
import User, { UserType } from '../redux/models/User';
import Loading from './Loading';
import { AppState } from '../redux/reducers';
import { connect } from 'react-redux';
import SuperadminRouter from './Superadmin/SuperadminRouter';
import ClientRouter from './Clients/ClientRouter';
import HelpDeskRouter from './HelpDesk/HelpDeskRouter';
import TechnicianRouter from './Technicians/TechnicianRouter';

interface Props {
    currentUser: User | undefined;
}

const Router: React.FunctionComponent<Props> = props => {
    const { currentUser } = props;
    if (currentUser != null) {
        if (currentUser.type === UserType.clientT1 || currentUser.type === UserType.clientT2) {
            return <ClientRouter />;
        } else if (currentUser.type === UserType.superadmin) {
            return <SuperadminRouter />;
        } else if (currentUser.type === UserType.technician) {
            return <TechnicianRouter />;
        } else if (currentUser.type === UserType.helpDesk) {
            return <HelpDeskRouter />;
        } else {
            return <h1>Not a valid user type! Contact your administrator.</h1>;
        }
    } else {
        return <Loading />;
    }
};

const mapStateToProps = (state: AppState) => ({
    firebaseUser: state.authentication.firebaseUser,
    currentUser: state.users.currentUser
});

export default connect(mapStateToProps)(React.memo(Router));
