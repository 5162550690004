import React from 'react';

interface Props {
    title: string;
    children: React.ReactNode;
}

const PageHeader: React.FunctionComponent<Props> = (props: Props) => (
    <div className='page-header'>
        <h4 className='page-title'>{props.title}</h4>
        <ol className='breadcrumb'>{props.children}</ol>
    </div>
);

export default PageHeader;
