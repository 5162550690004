import firebase from 'firebase/app';
import { Action } from 'redux';

export const LoadingFirebaseDataAction = 'LOADING_FIREBASE_USER';
export interface LoadingFirebaseDataActionType extends Action {
    type: typeof LoadingFirebaseDataAction;
    loading: boolean;
}

export const SaveFirebaseUserAction = 'SAVE_FIREBASE_USER';
export interface SaveFirebaseUserActionType extends Action {
    type: typeof SaveFirebaseUserAction;
    user: firebase.User | undefined | null;
}
