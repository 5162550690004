import React, { useState } from 'react';
import { connect } from 'react-redux';
import signInThunk from '../../redux/thunks/Authentication/signInThunk';
import { ThunkDispatch } from 'redux-thunk';
import SignForm from './SignForm';
import Form from 'react-bootstrap/Form';
import Input from '../Input';
import eyeLogoOff from '../../img/eye_logo_off.svg';
import eyeLogoOn from '../../img/eye_logo_on.svg';
import NoAuthentication from './NoAuthentication';
import { AppState } from '../../redux/reducers';
import { SaveCurrentUserActionType } from '../../redux/actions/users';

interface Props {
    signIn: (email: string, password: string) => Promise<void>;
}

const SignIn: React.FunctionComponent<Props> = (props: Props) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const onEmailChange = (email: string): void => setEmail(email);
    const onPasswordChange = (password: string): void => setPassword(password);
    const toggleShowPassword = (): void => setShowPassword(!showPassword);

    const signIn = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setShowSpinner(true);
        if (email && password) {
            try {
                await props.signIn(email, password);
            } catch (error) {
                setShowSpinner(false);
                alert(error.message);
            }
        }
    };

    return (
        <NoAuthentication>
            <SignForm
                title='Iniciar Sesión'
                showSpinner={showSpinner}
                loadingButtonTitle='Iniciar Sesión'
                alternativeButtonTitle='Crear Cuenta'
                redirectURL='/signup'
                onSubmit={signIn}
            >
                <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Input type='text' required onTextChange={onEmailChange} value={email} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Contraseña</Form.Label>
                    <div className='input-container'>
                        <img
                            src={showPassword ? eyeLogoOff : eyeLogoOn}
                            alt='search icon'
                            className='input-container__icon'
                            onClick={toggleShowPassword}
                        />
                        <Input
                            className='input-container__input'
                            pattern='(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$'
                            required
                            onTextChange={onPasswordChange}
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                        />
                    </div>
                </Form.Group>
            </SignForm>
        </NoAuthentication>
    );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, SaveCurrentUserActionType>): Props => ({
    signIn: async (email: string, password: string): Promise<void> => {
        await dispatch(signInThunk(email, password));
    }
});

export default connect(null, mapDispatchToProps)(SignIn);
