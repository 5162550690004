import React from 'react';
import User, { UserType, types } from '../../redux/models/User';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Input from '../Input';
import LoadingButton from '../LoadingButton';
import SelectInput from '../SelectInput';
import postUserAndRegisterThunk from '../../redux/thunks/Users/postUserAndRegisterThunk';
import { ThunkDispatch } from 'redux-thunk';
import Form from 'react-bootstrap/Form';
import eye_logo_off from '../../img/eye_logo_off.svg';
import eye_logo_on from '../../img/eye_logo_on.svg';

const finalUserTypes = types.delete(0).toArray();

interface Props {
    currentUser: User | undefined;
    postUserAndRegister: (user: User, password: string) => Promise<void>;
}

interface State {
    showSpinner: boolean;
    redirect: boolean;
    email: string;
    password: string;
    type: UserType;
    name: string;
    showPassword: boolean;
    lastname: string;
}

class SuperadminUserCreator extends React.PureComponent<Props, State> {
    public state: Readonly<State> = {
        showSpinner: false,
        redirect: false,
        email: '',
        password: '',
        type: UserType.supervisor,
        name: '',
        lastname: '',
        showPassword: false
    };

    public render() {
        return (
            <Container>
                <Row>
                    <Col xs={3} />
                    <Col xs={6}>
                        <Form>
                            <Form.Group>
                                <Input type='email' placeholder='email' required onTextChange={this.onEmailChange} />
                            </Form.Group>
                            <Form.Group>
                                <Input type='text' placeholder='name' required onTextChange={this.onNameChange} />
                            </Form.Group>
                            <Form.Group>
                                <Input
                                    type='text'
                                    placeholder='lastname'
                                    required
                                    onTextChange={this.onLastnameChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <div className='input-container'>
                                    <img
                                        src={this.state.showPassword ? eye_logo_off : eye_logo_on}
                                        alt='search icon'
                                        className='input-container__icon'
                                        onClick={this.toggleShowPassword}
                                    />
                                    <Input
                                        className='input-container__input'
                                        pattern='(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$'
                                        required
                                        placeholder='Contraseña'
                                        onTextChange={this.onPasswordChange}
                                        type={this.state.showPassword ? 'text' : 'password'}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <SelectInput options={finalUserTypes} onSelect={this.onOptionChange} />
                            </Form.Group>
                        </Form>
                        <LoadingButton
                            block
                            variant='success'
                            spinnerVariant='light'
                            disabled={this.state.showSpinner}
                            loading={this.state.showSpinner}
                            onClick={this.createUser}
                        >
                            Crear usuario
                        </LoadingButton>
                    </Col>
                    <Col xs={3} />
                </Row>
            </Container>
        );
    }

    private onEmailChange = (email: string) => this.setState({ email });
    private onNameChange = (name: string) => this.setState({ name });
    private onLastnameChange = (lastname: string) => this.setState({ lastname });
    private onPasswordChange = (password: string) => this.setState({ password });
    private onOptionChange = (type: string) => this.setState({ type: type as UserType });
    private createUser = async () => {
        this.setState({ showSpinner: true });
        const user = new User({
            email: this.state.email,
            type: this.state.type,
            name: this.state.name,
            lastname: this.state.lastname
        });
        try {
            await this.props.postUserAndRegister(user, this.state.password);
            this.setState({ showSpinner: false });
        } catch (error) {
            this.setState({ showSpinner: false });
            alert('El usuario ya existe');
        }
    };
    private toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
}

const mapStateToProps = (state: AppState) => ({
    currentUser: state.users.currentUser
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    postUserAndRegister: async (user: User, password: string) => {
        await dispatch(postUserAndRegisterThunk(user, password));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperadminUserCreator);
