import { Record } from 'immutable';
import { ComponentType } from 'react';

export interface Route {
    name: string;
    path: string;
    component?: ComponentType<any>; // eslint-disable-line
    icon: string;
    exact: boolean;
}

const RouteRecord = Record<Route>({
    name: '',
    path: '/',
    component: undefined,
    icon: '',
    exact: false
});

export default RouteRecord;
