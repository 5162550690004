import React from 'react';
import subscribeForAllAssignedTicketsThunk from '../../redux/thunks/Tickets/subscribeForAllAssignedTicketsThunk';
import { AppState } from '../../redux/reducers';
import User from '../../redux/models/User';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface Props {
    subscribeForAllAssignedTickets: (id: string) => Promise<void>;
    currentUser: User | undefined;
    children: Array<JSX.Element>;
}

class TicketsAssignedToCurrentUser extends React.PureComponent<Props> {
    public componentDidMount() {
        this.subscribeForAllUserTickets();
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.currentUser !== prevProps.currentUser) {
            this.subscribeForAllUserTickets();
        }
    }

    public render() {
        return this.props.children;
    }

    private subscribeForAllUserTickets() {
        if (this.props.currentUser != null) {
            this.props.subscribeForAllAssignedTickets(this.props.currentUser.id);
        }
    }
}

const mapStateToProps = (state: AppState) => ({
    currentUser: state.users.currentUser
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    subscribeForAllAssignedTickets: async (id: string) => {
        await dispatch(subscribeForAllAssignedTicketsThunk(id));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsAssignedToCurrentUser);
