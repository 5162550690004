import Ticket from '../models/Ticket';
import { Map } from 'immutable';

export const GetAllTicketsAction = 'GET_ALL_TICKETS';
export interface GetAllTicketsActionType {
    type: typeof GetAllTicketsAction;
    tickets: Map<string, Ticket>;
}

export const SaveTicketAction = 'SAVE_TICKET';
export interface SaveTicketActionType {
    type: typeof SaveTicketAction;
    ticket: Ticket;
}
