import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { dashboard, createUser, teamCodes, graficas } from './SuperadminRoutes';
import Users from './Users';
import TeamCodes from '../TeamCodes';
import Tickets from '../Tickets/Tickets';
import DateTickets from '../Tickets/dateTickets';

const SuperadminRouter = () => (
    <Tickets>
        <DateTickets>
            <Users>
                <TeamCodes>
                    <Route path='/' exact render={() => <Redirect to='/home' />} />
                    <Route path={dashboard.path} component={dashboard.component} exact={dashboard.exact} />
                    <Route path={graficas.path} component={graficas.component} exact={graficas.exact} />
                    <Route path={createUser.path} component={createUser.component} exact={createUser.exact} />
                    <Route path={teamCodes.path} component={teamCodes.component} exact={teamCodes.exact} />
                </TeamCodes>
            </Users>
        </DateTickets>
    </Tickets>
);

export default React.memo(SuperadminRouter);
