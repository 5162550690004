import { Record } from 'immutable';

export interface TeamCodeData {
    id: string;
    value: string;
}

const TeamCodeRecord = Record<TeamCodeData>({
    id: '',
    value: ''
});

export default class TeamCode extends TeamCodeRecord {}
