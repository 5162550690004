import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import Ticket from '../../redux/models/Ticket';
import User, { UserType } from '../../redux/models/User';
import moment from 'moment';

interface ReduxState {
    client?: User;
    technician?: User;
    helpDesk?: User;
    validator?: User;
}

interface ExternalProps {
    ticket: Ticket;
}

interface Props extends ReduxState, ExternalProps {}

class TicketView extends React.PureComponent<Props> {
    public render(): React.ReactNode {
        const { ticket, client, validator, helpDesk } = this.props;
        const creationDate = moment(ticket?.creationDate);
        const closeDate = ticket?.closeDate != null ? moment(ticket.closeDate) : undefined;
        return (
            <div id='Ticket' className='row'>
                <div className='col-md-12'>
                    <div className='card ticketCard  border'>
                        <div className='card-body'>
                            <div className='row mb-3'>
                                <div className='col-md-4'>
                                    <img className='ticketLogo' src={require('../../img/global_logo.png')} alt='Logo' />
                                </div>

                                <div className='col-md-8'>
                                    <div className='card border text-center'>
                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <p>REPORTE DE SERVICIO – Mantenimiento Correctivo</p>
                                                </div>
                                                <div className='col-md-8'>
                                                    <p>
                                                        Servicio de Arrendamiento de 800 Cámaras con Analíticos y <br />{' '}
                                                        Control de Acceso <br /> Contrato No. 035-O18-AICM-35.
                                                    </p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <p>
                                                        FO-EX-MC-01 <br /> CONTROL DE DOCUMENTACIÓN{' '}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='card-body'>
                                    <p>{`${client?.name} ${client?.lastname}`}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p>EQUIPO: {ticket?.objectID}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p>Ticket: {ticket?.id}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p>Hora de inicio: {creationDate.format('HH:mm')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p>Hora de finalización: {closeDate?.format('HH:mm')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p>Fecha: {closeDate?.format('DD/MM/YYYY')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p>Prioridad: {ticket?.priority}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p className='cardTitle'>Condición de Operación:</p>
                                            <p>Categoría: {ticket?.category}</p>
                                            <p>Incidencia: {ticket?.problem}</p>
                                            <p>Condición de Operación:</p>
                                            <p>{ticket?.description}</p>
                                            <p>{ticket?.place}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p className='cardTitle'>Intervención y diagnóstico:</p>
                                            <p>{ticket?.closeComment}</p>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <p>Fotos iniciales</p>
                                                    <div className='row'>
                                                        {ticket?.initialImages.slice(0, 2).map(image => (
                                                            <div className='col-md-6 ticketImage'>
                                                                <img alt='Imagen' src={image} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <p>Fotos Finales</p>
                                                    <div className='row'>
                                                        {ticket?.finalImages.slice(0, 2).map(image => (
                                                            <div className='col-md-6 ticketImage'>
                                                                {' '}
                                                                <img alt='Imagen' src={image} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p className='cardTitle'>Pausas</p>
                                            {ticket.pauseReasons.map(pauseReason => (
                                                <div
                                                    style={{
                                                        borderBottom: '1px solid rgba(255, 255, 255, 0.12)'
                                                    }}
                                                    key={pauseReason.id}
                                                >
                                                    <p>
                                                        Tiempo pausado:{' '}
                                                        {`${moment
                                                            .duration(
                                                                moment(pauseReason.endDate).diff(
                                                                    moment(pauseReason.creationDate)
                                                                )
                                                            )
                                                            .asHours()} horas`}
                                                    </p>
                                                    <p>Razón: {pauseReason.reason}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p>Estatus del ticket: {ticket?.closed ? 'Completado' : 'En proceso'}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <p className='cardTitle'>Validación</p>
                                            {validator != null ? (
                                                <p>
                                                    {`${validator?.name} ${validator?.lastname}`} <br />{' '}
                                                    {ticket.validationComment}
                                                </p>
                                            ) : (
                                                <p>{ticket.validationComment}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div style={{ height: '200px' }} className='card'>
                                        <div className='card-body'>
                                            <p className='cardTitle'>Nombre y Firma del AICM</p>
                                            <p>Firma:</p> <br />
                                            <p>Nombre:</p> <br />
                                            <p>Fecha:</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div style={{ height: '200px' }} className='card'>
                                        <div className='card-body'>
                                            <p className='cardTitle'>Nombre y Firma de Global</p>
                                            <p>Firma:</p> <br />
                                            <p>Nombre: {`${helpDesk?.name} ${helpDesk?.lastname}`}</p> <br />
                                            <p>Fecha: {closeDate?.format('DD/MM/YYYY')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 ticketFooter'>
                                    <p>
                                        Paseo de los Tamarindos 384 P9 INT 932
                                        <br />
                                        Col. Campestre Palo Alto Cuajimalpa de Morelos
                                        <br />
                                        Ciudad de México CP. 03100
                                        <br />
                                        Tel: (55)6714.8789
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: ExternalProps): ReduxState => {
    const ticket = ownProps.ticket;
    const client = state.users.clients.get(ticket.creatorID);
    let technician: User | undefined;
    if (state.users.currentUser?.type === UserType.technician) {
        technician = state.users.currentUser;
    } else {
        technician = ticket.technicianID != null ? state.users.technicians.get(ticket.technicianID) : undefined;
    }
    const helpDesk = ticket.helpDeskID != null ? state.users.helpDesks.get(ticket.helpDeskID) : undefined;
    const validator = ticket.validator != null ? state.users.clients.get(ticket.validator) : undefined;
    return {
        client,
        technician,
        helpDesk,
        validator
    };
};

export default connect(mapStateToProps)(TicketView);
