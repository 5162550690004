import {
    GetAllTeamCodesAction,
    GetAllTeamCodesActionType,
    SaveTeamCodeAction,
    SaveTeamCodeActionType,
    DeleteTeamCodeAction,
    DeleteTeamCodeActionType
} from '../actions/teamCodes';
import TeamCode from '../models/TeamCode';
import { Map } from 'immutable';

export const getAllTeamCodes: (teamCodes: Map<string, TeamCode>) => GetAllTeamCodesActionType = teamCodes => {
    return {
        type: GetAllTeamCodesAction,
        teamCodes
    };
};

export const saveTeamCode: (teamCode: TeamCode) => SaveTeamCodeActionType = teamCode => {
    return {
        type: SaveTeamCodeAction,
        teamCode
    };
};

export const deleteTeamCode: (teamCode: TeamCode) => DeleteTeamCodeActionType = teamCode => {
    return {
        type: DeleteTeamCodeAction,
        teamCode
    };
};
