import RouteRecord from '../Navigation/Route';
import HelpDeskDashboard from './HelpDeskDashboard';
import HelpDeskAllTickes from './HelpDeskAllTickes';
import HelpDeskUnassignedTickets from './HelpDeskUnassignedTickets';
import HelpDeskOpenTickets from './HelpDeskOpenTickets';
import HelpDeskPausedTickets from './HelpDeskPausedTickets';
import HelpDeskClosedTickets from './HelpDeskClosedTickets';
import HelpDeskReportGenerator from './HelpDeskReportGenerator';

export const dashboard = new RouteRecord({
    path: '/dashboard',
    component: HelpDeskDashboard,
    exact: true
});

export const allTickets = new RouteRecord({
    path: '/all_tickets',
    component: HelpDeskAllTickes,
    exact: true
});

export const unassignedTickets = new RouteRecord({
    path: '/unassigned_tickets',
    component: HelpDeskUnassignedTickets,
    exact: true
});

export const openTickets = new RouteRecord({
    path: '/open_tickets',
    component: HelpDeskOpenTickets,
    exact: true
});

export const pausedTickets = new RouteRecord({
    path: '/paused_tickets',
    component: HelpDeskPausedTickets,
    exact: true
});

export const closedTickets = new RouteRecord({
    path: '/closed_tickets',
    component: HelpDeskClosedTickets,
    exact: true
});

export const reportGenerator = new RouteRecord({
    path: '/report_generator',
    component: HelpDeskReportGenerator,
    exact: true
});
