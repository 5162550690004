import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    title: string;
    link: string;
    active: boolean;
}

const PageHeaderItem: React.FunctionComponent<Props> = (props: Props) => (
    <li className='breadcrumb-item'>
        <Link className={props.active ? 'text-white' : 'text-white-50'} to={props.link}>
            {props.title}
        </Link>
    </li>
);

export default PageHeaderItem;
