import { Map, Record } from 'immutable';
import {
    SaveUserAction,
    SaveUserActionType,
    GetAllClientsAction,
    GetAllClientsActionType,
    GetAllTechniciansAction,
    GetAllTechniciansActionType,
    SaveCurrentUserAction,
    SaveCurrentUserActionType,
    GetAllUsersAction,
    GetAllUsersActionType,
    DeleteUserAction,
    DeleteUserActionType,
    GetAllUsersForTypeActionType,
    GetAllUsersForTypeAction
} from '../actions/users';
import User, { UserType } from '../models/User';

export interface UsersState {
    clients: Map<string, User>;
    technicians: Map<string, User>;
    helpDesks: Map<string, User>;
    supervisors: Map<string, User>;
    users: Map<string, User>;
    currentUser: User | undefined;
}

const UserStateRecord = Record<UsersState>({
    clients: Map<string, User>(),
    technicians: Map<string, User>(),
    helpDesks: Map<string, User>(),
    supervisors: Map<string, User>(),
    users: Map<string, User>(),
    currentUser: undefined
});

const initialState = new UserStateRecord();

export default (
    state = initialState,
    action:
        | GetAllUsersForTypeActionType
        | SaveUserActionType
        | GetAllClientsActionType
        | GetAllTechniciansActionType
        | SaveCurrentUserActionType
        | GetAllUsersActionType
        | DeleteUserActionType
) => {
    switch (action.type) {
        case SaveUserAction:
            switch (action.user.type) {
                case UserType.clientT1:
                case UserType.clientT2:
                    return state.set('clients', state.clients.set(action.user.id, action.user));
                case UserType.technician:
                    return state.set('technicians', state.technicians.set(action.user.id, action.user));
                case UserType.helpDesk:
                    return state.set('helpDesks', state.helpDesks.set(action.user.id, action.user));
                case UserType.supervisor:
                    return state.set('supervisors', state.supervisors.set(action.user.id, action.user));
                case UserType.superadmin:
                default:
                    return state;
            }
        case DeleteUserAction:
            return state.set('users', state.users.delete(action.user.id));
        case GetAllUsersAction:
            const users = state.currentUser != null ? action.users.delete(state.currentUser.id) : action.users;
            return state.set('users', users);
        case SaveCurrentUserAction:
            return state.set('currentUser', action.user);
        case GetAllClientsAction:
            return state.set('clients', state.clients.concat(action.users));
        case GetAllTechniciansAction:
            return state.set('technicians', action.users);
        case GetAllUsersForTypeAction:
            switch (action.userType) {
                case UserType.clientT1:
                case UserType.clientT2:
                    return state.set('clients', state.clients.concat(action.users));
                case UserType.technician:
                    return state.set('technicians', state.clients.concat(action.users));
                case UserType.helpDesk:
                    return state.set('helpDesks', state.clients.concat(action.users));
                case UserType.supervisor:
                    return state.set('supervisors', state.clients.concat(action.users));
                case UserType.superadmin:
                default:
                    return state;
            }
        default:
            return state;
    }
};
