import { saveTicket } from '../../actionCreators/tickets';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import Ticket from '../../models/Ticket';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { SaveTicketActionType } from '../../actions/tickets';

const putTicketThunk = (ticket: Ticket) => async (
    dispatch: ThunkDispatch<void, AppState, SaveTicketActionType>
): Promise<void> => {
    await firebase
        .firestore()
        .collection(firestoreStructure.Tickets.name)
        .doc(ticket.id)
        .update(ticket.toJS());
    dispatch(saveTicket(ticket));
};

export default putTicketThunk;
