import React from 'react';
import putUserThunk from '../../redux/thunks/Users/putUserThunk';
import deleteUserThunk from '../../redux/thunks/Users/deleteUserThunk';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../redux/reducers';
import User, { types, UserType } from '../../redux/models/User';
import LoadingButton from '../LoadingButton';
import { connect } from 'react-redux';
import SelectInput from '../SelectInput';

const finalUserTypes = types.toArray();

interface Props {
    user: User;
    putUser: (user: User) => Promise<void>;
    deleteUser: (user: User) => Promise<void>;
}

interface State {
    loading: boolean;
}

class UserRow extends React.PureComponent<Props, State> {
    public state: Readonly<State> = {
        loading: false
    };

    public render() {
        return (
            <tr>
                <td>
                    <span className='text-muted'>{this.props.user.email}</span>
                </td>
                <td>
                    <span className='text-muted'>{`${this.props.user.name} ${this.props.user.lastname}`}</span>
                </td>
                <td>
                    <SelectInput
                        defaultValue={this.props.user.type}
                        options={finalUserTypes}
                        onSelect={this.onOptionChange}
                    />
                </td>
                <td className='text-muted'>
                    <LoadingButton
                        variant='danger'
                        onClick={this.deleteUser}
                        loading={this.state.loading}
                        className='w-100'
                    >
                        <i className='fa fa-trash'></i> Eliminar Usuario
                    </LoadingButton>
                </td>
                <td className='text-muted'>
                    <LoadingButton variant='warning' onClick={this.toggleDisable} loading={false} className='w-100'>
                        {this.props.user.disabled ? 'Reactivar' : 'Desactivar'}
                    </LoadingButton>
                </td>
            </tr>
        );
    }

    private toggleDisable = async () => {
        const newUser = this.props.user.set('disabled', !this.props.user.disabled);
        this.props.putUser(newUser);
    };

    private deleteUser = async () => {
        this.setState({ loading: true });
        await this.props.deleteUser(this.props.user);
        this.setState({ loading: false });
    };

    private onOptionChange = async (type: string) => {
        const newUser = this.props.user.set('type', type as UserType);
        this.props.putUser(newUser);
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    putUser: async (user: User) => {
        await dispatch(putUserThunk(user));
    },
    deleteUser: async (user: User) => {
        await dispatch(deleteUserThunk(user));
    }
});

export default connect(null, mapDispatchToProps)(React.memo(UserRow));
