import { Record, List } from 'immutable';

export enum NotificationType {
    newTicket = 'Nuevo ticket',
    closeTicket = 'Petición de cierre',
    assignedTicket = 'Nuevo ticket asignado',
    validateTicket = 'Validar ticket'
}

export const types = List([
    NotificationType.newTicket,
    NotificationType.closeTicket,
    NotificationType.assignedTicket,
    NotificationType.validateTicket
]);

export interface NotificationData {
    type: NotificationType;
    id: string;
    creationDate: Date;
    text: string;
}

const NotificationRecord = Record<NotificationData>({
    type: NotificationType.newTicket,
    id: '',
    creationDate: new Date(),
    text: ''
});

export default class Notification extends NotificationRecord {}
