import React from 'react';

interface Props {
    text: string | null;
    onClick?: () => void;
}

const TableRowText: React.FunctionComponent<Props> = (props: Props) => (
    <td>
        <span onClick={props.onClick} className='text-muted'>
            {props.text}
        </span>
    </td>
);

export default TableRowText;
