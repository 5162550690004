import React from 'react';
import Authenticated from './Authentication/Authenticated';
import firebase from 'firebase/app';
import 'firebase/auth';
import Router from './Router';
import { Link, Route } from 'react-router-dom';
import User, { UserType } from '../redux/models/User';
import { AppState } from '../redux/reducers';
import { connect } from 'react-redux';
import Profile from './Profile';
//import { dashboard } from './Technicians/TechnicianRoutes';

interface Props {
    currentUser: User | undefined;
    notificationsCount: number;
}

interface State {
    showNotificationMark: boolean;
}

class Root extends React.PureComponent<Props, State> {
    state: Readonly<State> = {
        showNotificationMark: true
    };

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.notificationsCount < this.props.notificationsCount && prevProps.notificationsCount !== 0) {
            this.setState({ showNotificationMark: true });
        }
    }

    public render() {
        return (
            <Authenticated>
                <div className='app-header header' style={{ backgroundColor: '#3f4759', minHeight: '70px' }}>
                    <div className='container-fluid'>
                        <img style={{ height: '50px' }} src={require('../img/global_logo.png')} alt='Logo' />
                        <div className='d-flex pull-right'>
                            {this.props.currentUser?.type === UserType.helpDesk ? (
                                <div onClick={this.removeNotificationMark}>
                                    <Link
                                        to='/notifications'
                                        className='nav-link icon'
                                        style={{ position: 'relative' }}
                                    >
                                        <i className='fa fa-bell-o'></i>
                                        {this.state.showNotificationMark ? (
                                            <span className='nav-unread bg-primary'></span>
                                        ) : null}
                                    </Link>
                                </div>
                            ) : null}

                            <div className='dropdown'>
                                <a className='nav-link pr-0 leading-none d-flex' data-toggle='dropdown' href='#'>
                                    <span className='avatar avatar-md brround'></span>
                                    <span className='ml-2 d-none d-lg-block'>
                                        <span className='text-white'>{`${this.props.currentUser?.name} ${this.props.currentUser?.lastname}`}</span>
                                    </span>
                                </a>
                                <div className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'>
                                    <Link className='dropdown-item' to='/profile'>
                                        <i className='fa fa-user' style={{ marginLeft: '2px' }}></i> &nbsp;&nbsp;Mi
                                        Perfil
                                    </Link>

                                    {this.props.currentUser?.name === 'Supervisor' ? (
                                        <Link className='dropdown-item' to='/dashboard'>
                                            <i className='fa fa-users' style={{ marginRight: '3px' }}></i> Usuarios y
                                            Equipos
                                        </Link>
                                    ) : (
                                        <a href=''></a>
                                    )}
                                    <a
                                        onClick={(): void => {
                                            firebase
                                                .auth()
                                                .signOut()
                                                .then(() => {
                                                    document.location.reload();
                                                });
                                        }}
                                        className='dropdown-item'
                                    >
                                        <i
                                            className='fa fa-times'
                                            style={{ marginRight: '11px', marginLeft: '4px' }}
                                        ></i>
                                        Cerrar Sesión
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Route path='/profile' component={Profile} exact={true} />
                <Router />
            </Authenticated>
        );
    }

    removeNotificationMark = () => this.setState({ showNotificationMark: false });
}

const mapStateToProps = (state: AppState): Props => ({
    currentUser: state.users.currentUser,
    notificationsCount: state.notifications.size
});

export default connect(mapStateToProps)(Root);
