import React from 'react';
import subscribeForAllTechniciansThunk from '../../redux/thunks/Users/subscribeForAllTechniciansThunk';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

interface Props {
    subscribeForAllTechnicians: () => Promise<void>;
    children: JSX.Element;
}

class Clients extends React.PureComponent<Props> {
    public componentDidMount() {
        this.props.subscribeForAllTechnicians();
    }

    public render() {
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    subscribeForAllTechnicians: async () => {
        await dispatch(subscribeForAllTechniciansThunk());
    }
});

export default connect(undefined, mapDispatchToProps)(Clients);
