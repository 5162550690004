import { getAllTickets } from '../../actionCreators/tickets';
import Ticket from '../../models/Ticket';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreStructure } from '../../models/FirebaseStructure';
import { Map } from 'immutable';
import parseTicketFromDocument from './parseTicketFromDocument';
import { UserType } from '../../models/User';

const subscribeForAllClientTickets = (clientType: UserType.clientT1 | UserType.clientT2) => (dispatch: any) => {
    return firebase
        .firestore()
        .collection(firestoreStructure.Tickets.name)
        .where('clientType', '==', clientType)
        .onSnapshot(ticketsData => {
            let tickets = Map<string, Ticket>();
            ticketsData.docs.forEach(ticketDocument => {
                const ticket = parseTicketFromDocument(ticketDocument);
                tickets = tickets.set(ticket.id, ticket);
            });
            dispatch(getAllTickets(tickets));
        });
};

export default subscribeForAllClientTickets;
