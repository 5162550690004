import React from 'react';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

interface Props {
    link: string;
    colorClass: string;
    title: string;
}

const ClientColumnButton: React.FunctionComponent<Props> = (props: Props) => (
    <Col lg={6} md={12}>
        <Link to={props.link}>
            <Card className={`widget-info ${props.colorClass}`} style={{ cursor: 'pointer' }}>
                <Card.Body>
                    <Row>
                        <Col xs={6} className='text-white'>
                            <h1>{props.title}</h1>
                        </Col>
                        <Col xs={6} className='text-right'>
                            <i className='fa fa-plus fa-3x text-white'></i>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Link>
    </Col>
);

export default React.memo(ClientColumnButton);
