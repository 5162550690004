import React from 'react';
import LoadingButton from '../LoadingButton';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import updateTeamCodeThunk from '../../redux/thunks/TeamCodes/updateTeamCodeThunk';
import deleteTeamCodeThunk from '../../redux/thunks/TeamCodes/deleteTeamCodeThunk';
import TeamCode from '../../redux/models/TeamCode';

interface ReduxFunctions {
    saveTeamCode: (teamCode: TeamCode) => Promise<void>;
    deleteTeamCode: (teamCode: TeamCode) => Promise<void>;
}

interface Props extends ReduxFunctions {
    teamCode: TeamCode;
}

interface State {
    loadingUpdate: boolean;
    loadingDelete: boolean;
}

class SuperadminTeamCodeRow extends React.PureComponent<Props, State> {
    public state: Readonly<State> = {
        loadingUpdate: false,
        loadingDelete: false
    };

    public render() {
        return (
            <tr>
                <td className='text-muted w-50'>{this.props.teamCode.value}</td>
                <td className='text-muted w-50'>
                    <LoadingButton className='mr-4' onClick={this.updateTeamCode} loading={this.state.loadingUpdate}>
                        Actualizar
                    </LoadingButton>
                    <LoadingButton onClick={this.deleteTeamCode} loading={this.state.loadingDelete} variant='danger'>
                        Eliminar
                    </LoadingButton>
                </td>
            </tr>
        );
    }

    private updateTeamCode = async (): Promise<void> => {
        this.setState({ loadingUpdate: true });
        const value = prompt('Nombre del equipo:');
        if (value != null) {
            const teamCode = this.props.teamCode.set('value', value);
            await this.props.saveTeamCode(teamCode);
            this.setState({ loadingUpdate: false });
        }
    };

    private deleteTeamCode = async (): Promise<void> => {
        this.setState({ loadingDelete: true });
        await this.props.deleteTeamCode(this.props.teamCode);
    };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>): ReduxFunctions => ({
    saveTeamCode: async (teamCode: TeamCode) => {
        await dispatch(updateTeamCodeThunk(teamCode));
    },
    deleteTeamCode: async (teamCode: TeamCode) => {
        await dispatch(deleteTeamCodeThunk(teamCode));
    }
});

export default connect(null, mapDispatchToProps)(SuperadminTeamCodeRow);
