import React from 'react';

export enum StatusType {
    success = 'bg-success',
    error = 'bg-danger',
    warning = 'bg-warning'
}

interface Props {
    text: string | null;
    status: StatusType;
}

const TableRowStatus: React.FunctionComponent<Props> = (props: Props) => (
    <td>
        <span className={`status-icon ${props.status}`} />
        <span className='text-muted'>{props.text}</span>
    </td>
);

export default TableRowStatus;
