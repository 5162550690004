import React from 'react';
import { Redirect } from 'react-router-dom';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Loading from '../Loading';

interface ReduxProps {
    firebaseUser: firebase.User | undefined | null;
    loading: boolean;
}

interface Props extends ReduxProps {
    children: React.ReactNode;
}

const Authenticated: React.FunctionComponent<Props> = (props: Props) => {
    if (props.firebaseUser === undefined || props.loading) {
        return (
            <div className='h-100'>
                <Loading />
            </div>
        );
    } else if (props.firebaseUser === null && props.loading === false) {
        return <Redirect to='/signin' />;
    } else {
        return <>{props.children}</>;
    }
};

const mapStateToProps = (state: AppState): ReduxProps => ({
    loading: state.authentication.loading,
    firebaseUser: state.authentication.firebaseUser
});

export default connect(mapStateToProps)(Authenticated);
