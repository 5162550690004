import React from 'react';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import Ticket from '../../redux/models/Ticket';
import { Map } from 'immutable';
import '../HelpDesk/HelpDeskMainTicketRow';
import TicketOverviewRow from '../Tickets/TicketOverviewRow';

interface Props {
    tickets: Map<string, Ticket>;
}

class HelpDeskClosedTickets extends React.PureComponent<Props> {
    public render() {
        const { tickets } = this.props;
        const ticketsArray = tickets
            .toList()
            .toArray()
            .filter(ticket => ticket.closed)
            .sort((ticketOne, ticketTwo) =>
                parseInt(ticketOne.id.slice(4)) < parseInt(ticketTwo.id.slice(4)) ? 1 : -1
            );

        return (
            <div>
                <div className='page'>
                    <div className='px-5'>
                        {/* <PageHeader
                            title='Dashboard'
                            items={[
                                {
                                    title: 'Dashboard',
                                    link: '/dashboard',
                                    active: false
                                },
                                {
                                    title: 'Tickets Cerrados',
                                    link: '/closed_tickets',
                                    active: true
                                }
                            ]}
                        /> */}
                        <div className='row row-cards row-deck'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h3 className='card-title'>Tickets Cerrados</h3>
                                    </div>
                                    <div className='table-responsive'>
                                        <table className='table card-table table-vcenter text-nowrap'>
                                            <thead>
                                                <tr>
                                                    <th className='w-1'>ID ticket</th>
                                                    <th>Fecha de creación</th>
                                                    <th>Fecha de atención</th>
                                                    <th>Cliente</th>
                                                    <th>Categoría</th>
                                                    <th>Incidencia</th>
                                                    <th>Técnico</th>
                                                    <th>Estatus</th>
                                                    <th>Prioridad</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ticketsArray.map(ticket => (
                                                    <TicketOverviewRow key={ticket.id} ticket={ticket} />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    tickets: state.tickets
});

export default connect(mapStateToProps)(React.memo(HelpDeskClosedTickets));
