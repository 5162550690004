import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import Loading from '../Loading';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

interface Props {
    children: React.ReactNode;
}

const Firebase: React.FunctionComponent<Props> = (props: Props) => {
    const [firebaseReady, setFirebaseReady] = useState(false);

    useEffect(() => {
        firebase.initializeApp(firebaseConfig);
        setFirebaseReady(true);
    }, []);

    return <>{firebaseReady ? props.children : <Loading />}</>;
};

export default Firebase;
