import {
    SaveFirebaseUserAction,
    SaveFirebaseUserActionType,
    LoadingFirebaseDataAction,
    LoadingFirebaseDataActionType
} from '../actions/authentication';
import firebase from 'firebase/app';

export const saveFirebaseUser: (user: firebase.User | null | undefined) => SaveFirebaseUserActionType = user => {
    return {
        type: SaveFirebaseUserAction,
        user
    };
};

export const setLoadingFirebaseData: (loading: boolean) => LoadingFirebaseDataActionType = loading => {
    return {
        type: LoadingFirebaseDataAction,
        loading
    };
};
