import { saveFirebaseUser } from '../../actionCreators/authentication';
import firebase from 'firebase/app';
import 'firebase/auth';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../reducers';
import { SaveFirebaseUserActionType } from '../../actions/authentication';

const signInThunk = (email: string, password: string) => async (
    dispatch: ThunkDispatch<void, AppState, SaveFirebaseUserActionType>
): Promise<void> => {
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
    dispatch(saveFirebaseUser(userCredential.user));
};

export default signInThunk;
