import React, { useEffect } from 'react';
import subscribeForAllClientsThunk from '../../redux/thunks/Users/subscribeForAllClientsThunk';
import { AppState } from '../../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { GetAllClientsActionType } from '../../redux/actions/users';

interface ReduxFunctions {
    subscribeForAllClients: () => Promise<void>;
}

interface Props extends ReduxFunctions {
    children: React.ReactNode;
}

const Clients: React.FunctionComponent<Props> = (props: Props) => {
    useEffect(() => {
        props.subscribeForAllClients();
    }, []);
    return <>{props.children}</>;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, GetAllClientsActionType>): ReduxFunctions => ({
    subscribeForAllClients: async (): Promise<void> => {
        await dispatch(subscribeForAllClientsThunk());
    }
});

export default connect(undefined, mapDispatchToProps)(Clients);
