import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducers';
import { RouteComponentProps } from 'react-router-dom';
import Ticket from '../../redux/models/Ticket';
import TicketView from './TicketView';
import Page from '../Page';
import PageHeader from '../PageHeader';
import PageHeaderItem from '../PageHeaderItem';

interface Params {
    ticketID: string;
}

interface ReduxState {
    ticket?: Ticket;
}

interface Props extends RouteComponentProps<Params>, ReduxState {}

class TicketPage extends React.PureComponent<Props> {
    public render(): React.ReactNode {
        return (
            <Page>
                <PageHeader title='Ticket'>
                    <PageHeaderItem title='Dashboard' link='/dashboard' active={false} />
                    <PageHeaderItem title='Tickets Cerrados' link={`/tickets/${this.props.ticket?.id}`} active={true} />
                </PageHeader>
                {this.props.ticket != null ? <TicketView ticket={this.props.ticket} /> : null}
            </Page>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: Props): ReduxState => {
    const ticket = state.tickets.get(ownProps.match.params.ticketID);
    return { ticket };
};

export default connect(mapStateToProps)(TicketPage);
