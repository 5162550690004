import * as React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import '../styles/LoadingButton.scss';

interface Props extends ButtonProps {
    onClick?: () => void;
    loading?: boolean;
    className?: string;
    spinnerVariant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
}

const LoadingButton: React.FunctionComponent<Props> = props => {
    const { loading, spinnerVariant, disabled, children, ...others } = props;
    return (
        <Button {...others} disabled={loading || disabled}>
            {props.loading ? <Spinner variant={spinnerVariant} animation='border' size='sm' /> : null}
            {children}
        </Button>
    );
};

export default LoadingButton;
