import React from 'react';
import { Link } from 'react-router-dom';
import Notification, { NotificationType } from '../redux/models/Notification';
import { List } from 'immutable';
import { connect } from 'react-redux';
import subscribeForNotificationsThunk from '../redux/thunks/Notifications/subscribeForNotifications';
import { AppState } from '../redux/reducers';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';

interface Props {
    notifications: List<Notification>;
    subscribeForNotifications: () => Promise<void>;
}

class Notifications extends React.PureComponent<Props> {
    public componentDidMount() {
        this.props.subscribeForNotifications();
    }

    public render() {
        return (
            <div>
                <div className='page'>
                    <div
                        style={{ paddingLeft: '30px', paddingTop: '0px', paddingRight: '30px', paddingBottom: '30px' }}
                    >
                        <div className='page-header'>
                            <h4 className='page-title'>Mis Notificaciones</h4>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to='/dashboard'>Home</Link>
                                </li>
                                <li className='breadcrumb-item active' aria-current='page'>
                                    Mis Notificaciones
                                </li>
                            </ol>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 offset-md-3'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h3 className='card-title'>Mis Notificaciones</h3>
                                    </div>
                                    <div className='card-body'>
                                        {this.props.notifications.map(notification => {
                                            return (
                                                <div
                                                    key={notification.id}
                                                    className='row ticket-card mt-3 pb-2 border-bottom pb-3 mb-3'
                                                >
                                                    <div className='ticket-details col-md-12'>
                                                        <div className='d-flex'>
                                                            <p className='text-white font-weight-bold mr-2 mb-0 no-wrap'>
                                                                {notification.type}
                                                            </p>
                                                        </div>
                                                        <p className='text-gray mb-2'>{notification.text}</p>
                                                        <div className='row text-gray d-md-flex d-none'>
                                                            <div className='col-6 d-flex'>
                                                                <p className='mb-0 mr-2 text-muted'>
                                                                    Fecha y hora de creación:
                                                                </p>
                                                                <p className='mr-2 mb-0 text-muted font-weight-light'>
                                                                    {`${moment(notification.creationDate).format(
                                                                        'DD/MM/YYYY HH:mm'
                                                                    )}`}
                                                                </p>
                                                            </div>
                                                            <div className='col-6 d-flex'></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    notifications: state.notifications.toList().sort((first, second) => {
        if (first.creationDate > second.creationDate) {
            return -1;
        } else {
            return 1;
        }
    })
});

const mapDispatchToProps = (dispatch: ThunkDispatch<void, AppState, any>) => ({
    subscribeForNotifications: async () => {
        await dispatch(subscribeForNotificationsThunk([NotificationType.newTicket, NotificationType.closeTicket]));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
