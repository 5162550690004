import RouteRecord from './Route';
import SignIn from '../Authentication/SignIn';
import SignUp from '../Authentication/SignUp';
import Root from '../Root';

// Routes

// Authentication

export const signIn = new RouteRecord({
    path: '/signin',
    component: SignIn,
    exact: true
});

export const signUp = new RouteRecord({
    path: '/signup',
    component: SignUp,
    exact: true
});

// Application

export const root = new RouteRecord({
    path: '/',
    component: Root,
    exact: false
});
