import {
    GetAllTicketsActionType,
    GetAllTicketsAction,
    SaveTicketAction,
    SaveTicketActionType
} from '../actions/tickets';
import Ticket from '../models/Ticket';
import { Map } from 'immutable';

export const initialState = Map<string, Ticket>();

export type TicketsState = typeof initialState;

export default (state = initialState, action: GetAllTicketsActionType | SaveTicketActionType) => {
    switch (action.type) {
        case GetAllTicketsAction:
            return state.concat(action.tickets);
        case SaveTicketAction:
            return state.set(action.ticket.id, action.ticket);
        default:
            return state;
    }
};
